<template>
  <van-tabbar v-model="active" inactive-color="#13b5b1" route active-color="orange">
    <van-tabbar-item replace to="/home_account">
      <span>{{ $t("Menu.home") }}</span>
      <img
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon.home_active : icon.home_inactive"
        alt=""
      />
    </van-tabbar-item>

    <van-tabbar-item replace to="/travel">
      <span>{{ $t("Menu.report") }}</span>
      <img
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon.travel_active : icon.travel_inactive"
        alt=""
      />
    </van-tabbar-item>

    <van-tabbar-item replace to="/home">
      <span>{{ $t("app.account") }}</span>
      <img
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon.account_active : icon.account"
        alt=""
      />
    </van-tabbar-item>


    <!-- <div class="tabbar-item"></div>

    <img class="addImg" @click="showMenu" :src="icon.add" alt="" /> -->
    <!-- <van-popup v-model="show" position="top" :style="{ height: '85%' }">
      <van-divider content-position="right">{{
        $t("Menu.profile")
      }}</van-divider>
      <van-grid :column-num="3">
        <van-grid-item
          icon="user-circle-o"
          :text="$t('Menu.accountInfo')"
          @click="hideMenu"
          to="/member/profile"
        />
        <van-grid-item
          icon="paid"
          :text="$t('Menu.bankInfo')"
          @click="hideMenu"
          to="/funds/bankinfo"
        />
        <van-grid-item
          icon="idcard"
          :text="$t('Menu.idInfo')"
          @click="hideMenu"
          to="/member/address"
        />
      </van-grid>
      <van-divider content-position="right">{{
        $t("Menu.accountManagent")
      }}</van-divider>
      <van-grid :column-num="3">
        <van-grid-item
          :icon="menu.shield"
          :text="$t('Menu.updateCrmPwd')"
          @click="hideMenu"
          to="/member/crmpwd"
        />
        <van-grid-item
          :icon="menu.security"
          :text="$t('Menu.updateMt4Pwd')"
          @click="hideMenu"
          to="/member/mt4pwd"
        />
        <van-grid-item
          :icon="menu.leverage"
          :text="$t('Menu.changeLeverage')"
          @click="hideMenu"
          to="/member/leverage"
        />
      </van-grid>
      <van-divider content-position="right">{{
        $t("Menu.fundsManagent")
      }}</van-divider>
      <van-grid :column-num="4">
        <van-grid-item
          icon="balance-pay"
          :text="$t('Menu.deposit')"
          @click="hideMenu"
          to="/funds/deposit"
        />
        <van-grid-item
          icon="balance-o"
          :text="$t('Menu.withdrawals')"
          @click="hideMenu"
          to="/funds/withdrawals"
        />
        <van-grid-item
          icon="refund-o"
          :text="$t('Menu.transfer')"
          @click="hideMenu"
          to="/funds/transfer"
        />
        <van-grid-item
          icon="balance-list-o"
          :text="$t('Menu.fundsHistory')"
          @click="hideMenu"
          to="/funds/history"
        />
      </van-grid>
      <van-divider content-position="right">{{
        $t("Menu.community")
      }}</van-divider>
      <van-grid :column-num="3">
        <van-grid-item
          icon="award-o"
          :text="$t('Menu.signal')"
          @click="hideMenu"
          to="/community"
        />
        <van-grid-item
          icon="flag-o"
          :text="$t('Menu.myCopy')"
          @click="hideMenu"
          to="/signal/mycopy"
        />
        <van-grid-item
          icon="medal-o"
          :text="$t('Menu.mySignal')"
          @click="hideMenu"
          to="/signal/mysignal"
        />
      </van-grid>
      <van-divider content-position="right">{{
        $t("Menu.reportCenter")
      }}</van-divider>
      <van-grid :column-num="3">
        <van-grid-item
          icon="description"
          :text="$t('Menu.closeReport')"
          @click="hideMenu"
          to="/report/ordershistory"
        />
        <van-grid-item
          icon="records"
          :text="$t('Menu.positionReport')"
          @click="hideMenu"
          to="/report/position"
        />
        <van-grid-item
          icon="after-sale"
          :text="$t('Menu.depositReport')"
          @click="hideMenu"
          to="/report/business"
        />
        <van-grid-item
          icon="certificate"
          :text="$t('Menu.creditReport')"
          @click="hideMenu"
          to="/report/credit"
        />
        <van-grid-item
          icon="photo-o"
          :text="$t('Menu.commissionReport')"
          @click="hideMenu"
          to="/report/commission"
          :style="{ display: userinfo.haveIbLogin == true ? 'block' : 'none' }"
        />
        <van-grid-item
          icon="photo-o"
          :text="$t('Menu.commissionTotal')"
          @click="hideMenu"
          to="/report/bonus"
          :style="{ display: userinfo.haveIbLogin == true ? 'block' : 'none' }"
        />
        <van-grid-item
          icon="photo-o"
          :text="$t('Menu.summaryReport')"
          @click="hideMenu"
          to="/report/summary"
        />
      </van-grid>
    </van-popup> -->
    <van-tabbar-item replace to="/community" v-show="showSingal">
      <span>{{ $t("Menu.signal") }}</span>
      <img
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon.news_active : icon.news_inactive"
        alt=""
      />
    </van-tabbar-item>

    <van-tabbar-item replace to="/member">
      <span>{{ $t("Menu.mine") }}</span>
      <img
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon.mine_active : icon.mine_inactive"
        alt=""
      />
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import baseConfig from '@/utils/baseConfig'
export default {
  name: "Tabbar",
  data() {
    return {
      active: 0,
      show: false,
      showSingal: baseConfig.showSingal,
      icon: {
        home_active: require("../assets/image/tabbar/home_active.svg"),
        home_inactive: require("../assets/image/tabbar/home.svg"),
        travel_active: require("../assets/image/tabbar/travel_active.svg"),
        travel_inactive: require("../assets/image/tabbar/travel.svg"),
        news_active: require("../assets/image/tabbar/news_active.svg"),
        news_inactive: require("../assets/image/tabbar/news.svg"),
        mine_active: require("../assets/image/tabbar/mine_active.svg"),
        mine_inactive: require("../assets/image/tabbar/mine.svg"),
        account: require("../assets/image/tabbar/account.svg"),
        account_active: require("../assets/image/tabbar/account_active.svg"),
        add: require("../assets/image/tabbar/add.svg"),
      },
      menu: {
        shield: require("../assets/image/shield.png"),
        security: require("../assets/image/security.png"),
        leverage: require("../assets/image/leverage.png"),
      },
      userinfo: this.$store.state.userinfo,
    };
  },
  methods: {
    showMenu() {
      this.show = true;
    },
    hideMenu() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.addImg {
  width: 54px;
  height: 54px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -27px;
  bottom: 14px;
}

.tabbar-item {
  flex: 1;
}
</style>
