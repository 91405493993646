export default {
    app: {
        login: '登录',
        language: "语言",
        accountpwdlogin: "登录",
        pleaseinputaccount: "请输入账号",
        pleaseinputpassword: "请输入密码",
        forgetpassword: "忘记了?",
        refundpassword: "找回密码",
        noaccount: "没有帐户",
        registernow: "立即注册",
        loginemailorlogin: "电子邮件/账号",
        sildesuccesstext: "验证通过！",
        sildefailtext: "验证失败，请重试",
        slidertext: "拖动滑块完成拼图",
        pleaseinputrepassword: "请再次输入密码",
        clickLoadMore: '点击加载更多',
        noMoreData: '没有更多了',
        more: '更多',
        account: '账号',
        delete: "删除",
        transactionignal: '交易信号',
        switchAccount: '切换账号',
        myCard: '我的卡',
        improve: '请完善银行卡信息',
        recomment: "推荐",
        btc: "比特币",
        binding: "添加",
        bind: "绑定其他银行卡",
        common: "共",
        zhang: "张",
        add: "添加",
        center: "应用中心",
        promote: "推广",
        security: "安全防护",
        photograph: "拍照",
        photo: "从手机相册选择",
        not: '可不填',
        break: "松开刷新",
        emailErr: "请检查你的输入然后重试!",
        email: "不正确的电子邮件地址格式",
    },
    uploadFileRangeSize: '文件大小不超过3MB',
    sumProfit: "总收益",
    netprofitincludefee: "净利润(包含费用)",
    pleaseinputsinglaname: "请输入信号名称",
    pleaseinputpercentagesignalcom: "请输入信号佣金",
    pleaseinputpercentageparentcom: "请输入推荐人佣金",
    closePositionsFirst: "请先平仓!",
    hasSubscription: "信号源有跟单账户，无法完成操作!",

    Status: "状态",
    ordernumber: "订单号",
    commission: "佣金",
    referralLink: '推广链接',
    clickToCopy: '点击复制',
    android: "安卓",
    ios: "苹果",
    windows: "桌面",
    sendemailsuccess: "发送成功",
    sendemailfailure: "发送失败，请稍后再试",
    accountdisabled: "账号被禁用",
    emailnotexisting: "邮箱不存在",
    alreadysubmitunscribe: "已经提交了撤离申请",
    createfailpleasetryagainlater: "操作失败，请稍后再试",
    alreadnotcopy: "已经不再跟单",
    directly: "直属",
    secondary: "从属",
    parent: "推荐人",
    singalhaveoption: "信号源有持仓",
    resetCrmPwd: '重置密码',
    pleaseConfirmNewPwdBefore:'请为',
    pleaseConfirmNewPwdAfter:'设置新的登录密码',
    retrievepwd: "找回密码",
    resetpwdlinksendtoyouremail: "修改密码链接将会发至您的注册邮箱，请注意查收!",
    pleaseinputemail: "请输入邮箱",
    notrightformatemail: "邮箱格式不正确",
    ok: "确认",
    wrongnameorpassword: "错误的账号或密码",
    clicktologinroregister: "点击登录/注册",
    completematerial: "完善资料",
    personalinformation: "个人信息",
    identityinformaiton: "证件信息",
    perfectsuccess: "完善成功",
    phone: "电话",
    areacode: "区号",
    pleaseinputphone: "请输入号码",
    country: "国籍",
    pleaseselectcountry: "请选择国家",
    province: "省份",
    pleaseselectprovince: "请选择省份",
    city: "城市",
    pleaseselectcity: "请选择城市",
    username: "用户名",
    address: "地址",
    pleaseinputaddress: "请输入地址",
    submit: "提交",
    cancel: "取消",
    front: "正面",
    back: "反面",
    proofofaddress: "地址证明",
    opentradeaccount: "开交易账户",
    openagentaccount: "开代理账户",
    tohome: "返回主页",
    uploadsuccess: "上传成功",
    uploading: "上传中",
    pleaseuploadpicture: "请上传图片",
    pleaseinputprovinceorcity: "请填写省份或城市",
    openaccount: "开户",
    churujin: "出/入金",
    copyorder: "跟单",
    wallet: "钱包",
    mycustomers: "我的下级",
    mydocument: "我的证件",
    iwanttopromote: "我要推广",
    downloadtradeclient: "下载交易端",
    myapplication: "我的申请",
    applycopy: "申请跟单",
    copyapply: "跟单申请",
    mycopy: "我的跟单",
    myapplycopy: "我的申请",
    applysignal: "申请信号",
    signalapply: "信号申请",
    mysignal: "我的信号",
    notlogin: "您还未登录？",
    areyousurethatlogout: "确定退出？",
    toback: "返回",
    avater: "头像",
    email: "邮箱",
    unbound: "未绑定",
    pleaseinputphone: "请填写电话号码",
    success: "操作成功",
    copyapply: "跟单申请",
    tips: "提示",
    notrepeatandnotsignal: "此账号不能重复跟单，且不是信号源!",
    signalname: "信号源",
    pleaseselect: "请选择",
    copyaccount: "跟单账号",
    copytype: "跟单方式",
    copyvalue: "跟单值",
    copydirection: "跟单方向",
    pleaseinput: "请输入",
    reverse: "反向",
    positive: "正向",
    percentage: "百分比",
    fixedlot: "固定手数",
    equityratio: "净值比例",
    percentageholder: "请输入(%)",
    fixedlotholder: "请输入固定手数，最小0.01",
    equityratioholder: "禁止输入",
    copypercentagemustgreaterzero: "跟单百分比必须大于0",
    copyvolumesamllest: "固定手数最小0.01",
    createfailpleasetryagainlater: "操作失败请稍后再试",
    systemerror: "系统错误",
    createsuccess: "操作成功",
    signalname: "信号源",
    signalstate: "信号源状态",
    copystate: "跟单状态",
    clicktoloadmore: "点击加载更多",
    nomore: "没有更多了",
    SignalCom: "信号佣金",
    ParentCom: "推荐人佣金",
    profitShareLogin: "分红账户",
    unavailable: "不可用",
    normal: "可用",
    failure: "失败",
    applying: "申请中",
    success: "成功",
    disable: "已禁用",
    deleted: "已删除",
    areyousureleavecopy: "确定取消跟单？",
    lots: "手",
    none: "无",
    pleaseselect: "请选择",
    login: "账户",
    pleaseinputpercentage: "请输入(%)",
    loginnoeempty: "账号不许为空",
    signalnamenotempty: "信号名字不许为空",
    signalcommduringzerotoonehundred: "信号源佣金应在0到100%",
    parentcommduringzerotoonehundred: "推荐人佣金应在0到100%",
    thesumcommmusetlessonehundred: "佣金总和不得大于100%",
    revoke: "撤离",
    alllogins: "全部账号",
    applyforunsubscribe: "申请撤离",
    areyousuredisalbesiganl: "确定禁用此信号？",
    areyousureactivethesiganl: "确定启用此信号?",
    areyousuredeletethesignal: "确定删除此信号?",
    disabled: "禁用",
    applying: "处理中",
    cooperationsignal: "合作信号",
    freesignal: "江湖信号",
    AllProfit: "总盈利",
    WeekProfit: "周盈利",
    MonthProfit: "月盈利",
    ProfitPercent: "盈利系数",
    Name: "信号源",
    CreateTime: "创建时间",
    banlancetrend: "资金走势图($)",
    signaldetail: "信号详情",
    Balance: "余额",
    Equity: "净值",
    Deposit: "总入金",
    Withdrawal: "总出金",
    AllProfit: "盈利",
    Profit2: "亏损",
    Profit: "净利润(包含费用)",
    ProfitPercent: "盈利系数",
    Volume: "交易量",
    TicketCount: "订单数量",
    TicketBuy: "多单数量",
    TicketSell: "空单数量",
    ProfitCount1: "盈利订单",
    ProfitCount2: "亏损订单",
    tradesummary: "交易概况",
    TradeNum: "总交易量",
    TradeCycle: "交易天数",
    MaxDrawdown: "最大回撤",
    WeekProfit: "近一周收益",
    MonthProfit: "近一月收益",
    AllProfit: "总收益",
    Copy: "立即跟单",
    tokenexpried: "认证过期，请重新登录",
    termail: "交易终端",
    download: "下载",
    //end

    sort: '排序',
    sortByTime: '时间',
    sortByAmount: '金额',
    sortByProfit: '利润',
    sortByDeposit: '入金',
    sortByWithdrawal: '出金',
    allAccount: '全部账号',

    notLoggenIn: '您还未登录',
    confirmExit: '确定退出系统?',
    developing: '功能暂未上线~',
    dataerror: "请检查您的输入并重试！",
    success: "操作成功!",
    networkError: "网络错误",
    codeSendSuccess: "验证码发送成功!",
    codeSendFailed: "验证码发送失败!",
    tryAgainLater: "请稍后在尝试!",
    verificationCode: "验证码",
    sendVerificationCode: "发送验证码",
    pleaseInputVerificationCode: "请输入验证码",
    loading: "处理中",
    commonTools: "常用工具",
    changePassword: "修改密码",
    customerManagement: '客户管理',
    forbiddenToLogin: "禁止登录",
    Register: {
        register: "注册",
        hasAccount: "已有账户",
        loginNow: "立即登录",
        emailFormatError: "邮箱格式不正确",
        pwdStrongError: "密码强度不够",
        mailAlreadyExists: "该账户已注册,请直接登录!",
        registerFailed: "注册邮件发送失败,请联系客服!",
        activeExpired: "验证已过期,请重新发送邮件激活",
        activeSuccess: "激活成功",
        userEmail: "邮箱",
        userName: "姓名",
        userPassword: "密码",
        userPasswordAgain: "确认密码",
        userAgent: "推荐人",
        registerSuccess: "注册成功,请前往邮箱进行激活!",
        pwdNotMatch: "两次输入的密码不一致!",
    },
    NewAccount: {
        accountApplication: "账户申请",
        verifyExpired: "验证失败",
        alreadyMaxCount: "账户数量已达到上限",
        loginTypeError: "错误的账户类别",
        loginOutOfRange: "账户超出范围!",
        loginOpenSuccess: "账户开设成功!",
        loginApplicationSuccess: "开户申请提交成功!",
        loginApplicationFailed: "开户申请提交失败!",
        accountType: "账户类型",
        chooseAccountType: "选择账户类型",
        leverage: "杠杆",
        chooseLeverage: "选择杠杆",
        submitApplication: "提交申请",
        tradingAccount: '交易账户',
        agentAccount: '代理账户',
    },
    Funds: {
        deposit: "入金",
        withdrawals: "取款",
        transfer: "转账",
        login: "账户",
        chooseLogin: "选择账户",
        submitApplication: "提交",
        withdrawalsMoneyUsd: "取款金额($)",
        pleaseInputWithdrawalsMoneyUsd: "请输入取款金额",
        depositMoney: "入金金额",
        depositMoneyUsd: "美元",
        pleaseInputDepositMoney: "请输入入金金额",
        pleaseInputDepositMoneyUsd: "入金金额($)",
        freeBalance: "可用余额",
        currency: "货币选择",
        pleaseChooseCurrency: "请选择货币",
        bankInfo: "银行信息",
        pleaseChooseBankInfo: "请选择银行信息",
        balanceError: "获取失败,请稍后在尝试!",
        noBankCard: "请添加收款银行",
        notEnoughMoney: "可用余额不足",
        subscribersOnlyWithdrawOnSunday: "跟单用户请在每周周日提交出金申请!",
        dailyWithdrawalsLimit: "取款达到单日最高限额!",
        applicationSuccess: "申请提交成功!",
        applicationError: "申请提交失败!",
        transAmount: "转账金额",
        pleaseInputTransAmount: "请输入转账金额",
        transLogin: "收款账户",
        pleaseInputTransLogin: "请输入转账账户",
        bankCardUsdtBtc: "钱包",
        walletAddress: '收款地址',
        bankCard: '银行卡',
        edit: '编辑',
        bankCardAdd: "新增银行信息",
        bankCardEdit: "编辑银行信息",
        bankName: "银行名称",
        pleaseInputBankName: "请输入银行名称",
        bankCardId: "银行卡号",
        pleaseInputBankCardId: "请输入银行卡号",
        bankUsdtAdd: "新增USDT地址",
        bankUsdtEdit: "编辑USDT地址",
        bankCardUsdt: "USDT地址",
        pleaseInputBankCardUsdt: "请输入USDT收款地址",
        bankBtcAdd: "新增BTC地址",
        bankBtcEdit: "编辑BTC地址",
        bankCardBtc: "BTC地址",
        pleaseInputBankCardBtc: "请输入BTC收款地址",
        swiftCode: "SWIFT Code",
        pleaseInputSwiftCode: "SWIFT Code(可空)",
        branchName: "分行名称",
        pleaseInputBranchName: "请输入分行名称",
        bankAddress: "银行地址",
        pleaseInputBankAddress: "请输入银行地址",
        payChannel: "支付通道",
        pleaseInputPayChannel: "请选择支付通道",
        doNotRepeatUpload: "请勿重复上传",
        errorImageFormat: "请上传图片",
        uploadCert: "上传凭证",
        pleaseChoosePaymentChannel: '请选择一个支付通道',
        processing: '处理中',
        refused: '已拒绝',
        completed: '已完成',
        viewCert: '查看凭证',
        minWithdrawal:'最低出金金额:',
        maxMinDeposit: '支付金额:',
        usdtTypeName: '链名称',
        totalAmount: '全部金额',
        withdrawal: '提现',
        recharge: '充值',
        bankAccount: '银行账户',
    },
    Leverage: {
        changeLeverage: '调整杠杆',
        leverage: '杠杆',
        leverageOld: '原杠杆',
        leverageNew: '新杠杆',
        pleaseChooseLeverageNew: '请选择杠杆',
    },
    ChangePwd: {
        changeCrmPwd: '修改CRM登录密码',
        changeMt4Pwd: '修改MT5密码',
        mainPassword: '交易密码',
        investorPassword: '只读密码',
        userName: '用户名',
        password: '密码',
        pleaseInputPassword: '请输入密码(至少8位)',
        passwordAgain: '确认密码',
        pleaseInputPasswordAgain: '请输入确认密码(至少8位)',
        pwdChangeSuccess: '密码修改成功,请重新登录',
        passwordType: '密码类型',
        pleaseChoosePasswordType: '请选择密码类型',
        pwdLengthError: "密码长度8至18位",
        validationFailure: '验证失败,请重新尝试',
        setPasswordSuccess:'密码重置成功'
    },
    AccountInfo: {
        userList: '用户列表',
        leverage: "杠杆",
        group: "组",
        equity: '净值',
        balance: '余额',
        credit: '信用',
        freeMargin: '可用保证金',
        regDate: '开户日期',
        moreDetail: '更多详情',
    },
    Menu: {
        home: '首页',
        report: '报表',
        signal: '信号',
        mine: '我的',
        profile: '个人信息',
        accountInfo: '账户信息',
        bankInfo: '银行信息',
        idInfo: '证件信息',
        accountManagent: '账户管理',
        updateCrmPwd: 'CRM密码',
        updateMt4Pwd: '交易账户密码',
        changeLeverage: '调整杠杆',
        fundsManagent: '出入金',
        deposit: "入金",
        withdrawals: "取款",
        transfer: "转账",
        fundsHistory: '账户流水',
        reportCenter: '报表中心',
        closeReport: '平仓报告',
        positionReport: '持仓报告',
        depositReport: '业绩报告',
        creditReport: '信用报告',
        commissionReport: '佣金报告',
        commissionTotal: '佣金统计',
        summaryReport: '汇总报告',
        community: '跟单社区',
        myCopy: '跟单社区',
        mySignal: '跟单社区',
        cancel: '取消',
        totalBalance: '余额',
        totalWithdrawal: '出金',
        totalDeposit: '入金',
        logOut: '退出',
        aboutUs: '关于我们',
        myApplyApplication: '我的申请',
        openAccount: '开户',
        depositOrWithdrawal: '出/入金',
        copyTrade: '跟单',
        wallet: '钱包',
        underling: '我的客户',
        credentials: '我的证件',
        myLink: '我要推广',
        downloadMetatrade: '下载交易端',
        createSignal: '申请信号',
        mySignal: '我的信号',
        myApplySignalLog: '我的申请',
        aplyForCopy: '申请跟单',
        myCopies: '我的跟单',
        myApplyCopyLog: '我的申请',
        toTrading:"我要交易",
        performance: "业绩报表",
    },
    Report: {
        commissionTotal: '佣金统计',
        volume: '交易量',
        balance: '余额',
        swaps: '库存费',
        taxes: '税金',
        credit: '信用',
        depossitTotal: '入金',
        withdrawalTotal: '出金',
        takeProfit: '止盈',
        stopLoss: '止损',
        charges: '手续费',
        commission: '佣金',
        commissionAgent: '代理佣金',
        shareProfit: '分红',
        signalCommission: '信号源佣金',
        totalCommission: '总佣金',
        pleaseChooseLogin: '请选择账户'
    },
    alreadywassignal: '已经是信号源',
    loginnotexist: '账号不存在',
    alreadycopyingsignal: '正在跟单',
    alreadyfollowthesignal: '已经在跟当前信号',
    thisloginsubmitsignalapply: '已经提交信号申请',
    alreadysubmitfolowapply: '已经提交跟单申请',
    Mt4ApiMenu: {
        quote: "行情",
        chart: "图表",
        trade: "交易",
        history: "历史",
        settings: "设置",
        agreement: "用户协议",
        privacyPolicy: "隐私政策",
        aboutUs: "关于我们",
        loginToCrm: "管理端登录",
        loginToMt4:"交易端登录"
    },
    Mt4ApiTrade: {
        margin: "预付款",
        marginFree: "可用预付款",
        marginLevel: "预付款比率",
        profit: "利润",
        selectDate: "选择日期",
        stopLoss: '止损',
        takeProfit: '止盈',
        pendingPrice: '价格',
        pendingExpDate: '期限',
        ticket: '订单',
        delete: '删除',
        addSymbol: "添加自选",
        spread: "点差",
        high: "最高",
        low: "最低",
        showAll: "查看全部",
        searchSymbol: "搜索交易品种",
        tradeWarning: "注意：市价交易模式下的实际成交价格，可能会和请求价格有一定差异！",
        newAccount: "新账户",
        openNewAccount: "注册新账户",
        openNewAccountDesc: "选择经纪人并注册一个帐户以学习交易并测试您的策略",
        loginWithNewAccount: "切换交易账户",
        loginWithNewAccountDesc: "使用您的登录名/密码连接您选择的交易商",
        account: "交易账户",
        password: "密码",
        toCrm: "会员中心",
        positionOrder: '持仓',
        pendingOrder: '挂单',
        closeOrder:'平仓',
        deleteOrder:'删除',
        modifyOrder: '修改',
        trade: '交易',
        buyLimit: '限价买入',
        buyStop: '买入止损',
        sellLimit: '限价卖出',
        sellStop: '卖出止损',
        marketExecution: '市价执行',
        sellByMarket:'市价卖出',
        buyByMarket:'市价买入'


    },
    KLineChart: {
        time:"时间",
        open:"开",
        close:"收",
        high:"高",
        low:"低",
        volume: "成交量",
        candleSolid:"蜡烛实心",
        candleSStroke:"蜡烛空心",
        candleUpStroke:"蜡烛涨空心",
        candleDownStroke:"蜡烛跌空心",
        candleOhlc:"OHLC",
        candleArea:"面积图",
    },
    LoginIsError:"登录失败",
    LogoutIsError:"退出失败",
    DataIsNULL: "数据为空",
    AcceptRequestError: "接受请求失败",
    InvalidSlOrTp: "无效止盈与止损",
    CheckStopsAndExpirationError: "无效止盈与止损",
    CheckTradeRequestError: "请求失败",
    GetKdataError: "获得K线数据失败",
    GetSymbolByCurrencyError: "获得品种失败",
    GetOpenOrderByTicketError: "获得订单失败",

}