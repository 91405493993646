export default {

    terminal: 0, //0:Crm  1:Trade   2:Crm&Trade
    chart: 1, // 1.TradingView 2. kline
    showSingal: true,
    //======================   Debug   ============================//

    ////baseURL
    ////apiUrl: "http://192.168.1.120:8090/",
    //apiUrl: "http://localhost:53333/",
    ////socket
    //socketPath: "ws://192.168.1.111:10600/quote",
    ////timeout
    //timeOut: 1000 * 60 * 3,
    ////img upload
    //imageUrl: "https://files.crazyworld.vip/fileupload/index",
    ////img base64 upload
    //imageBase64Url: "https://files.crazyworld.vip/fileupload/filebase64",


    //=====================   End   Debug   =========================//

    // 
    // //baseURL
    //apiUrl: "https://api.crazyworld.vip/",
    ////socket
    //socketPath: "ws://208.109.8.217:10600/quote",
    ////timeout
    //timeOut: 1000 * 60 * 1,
    ////img upload
    //imageUrl: "https://files.crazyworld.vip/fileupload/index",
    ////img base64 upload
    //imageBase64Url: "https://files.crazyworld.vip/fileupload/filebase64",


    //======================   Release  ============================//

    //baseURL
    apiUrl: "https://api.jiusunfx.com/",
    //socket
    socketPath: "ws://208.109.8.217:10600/quote",
    //timeout
    timeOut: 1000 * 60 * 1,
    //img upload
    imageUrl: "https://files.jiusunfx.com/fileupload/index",
    //img base64 upload
    imageBase64Url: "https://files.jiusunfx.com/fileupload/filebase64",

    //===================   End Release ============================//




    //===================   KLine Chart ============================//

    //dark
    textColorDark: "#929AA5",
    gridColorDark: "#292929",
    axisLineColorDark: "#333333",
    crossTextBackgroundColorDark: "#373a40",
    //light
    textColorLight: "#76808F",
    gridColorLight: "#ededed",
    axisLineColorLight: "#DDDDDD",
    crossTextBackgroundColorLight: "#686d76",

    backgroundColorDark: "#1f2126",

    timeZone: "Europe/Berlin",
    kLineTheme: "light", //dark��light


    //===================End   KLine Chart =========================//


}