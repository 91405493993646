import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {Locale} from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhHK from 'vant/lib/locale/lang/zh-HK'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import enLocale from './en_us'
import zhLocale from './zh_cn'
import jpLocale from './ja_jp'
import thLocale from './th_th'
import hkLocale from './zh_tw'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  jp: {
    ...jaJP,
    ...jpLocale
  },
  th: {
    ...enUS,
    ...thLocale
  },
  hk: {
    ...zhHK,
    ...hkLocale
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',  
  messages: messages
})

function vantLocales (lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  }else if (lang === 'th') {
    Locale.use(lang, enUS)
  }else if (lang === 'jp') {
    Locale.use(lang, jaJP)
  }else if (lang === 'hk') {
    Locale.use(lang, zhHK)
  }
}

export {i18n, vantLocales}