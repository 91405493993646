<template>
    <div id="app">
        <div>
            <keep-alive>
                <router-view v-if="this.$route.meta.keepAlive"
                             :class="[this.tabbarShow ? 'content-height' : 'page-height']" />
            </keep-alive>
            <router-view v-if="!this.$route.meta.keepAlive"
                         :class="[this.tabbarShow ? 'content-height' : 'page-height']" />
            <div v-if="this.tabbarCurrentShow == 'Crm'">
                <tabbar v-show="this.tabbarShow" active-color="#13b5b1" />
            </div>
            <div v-if="this.tabbarCurrentShow == 'Mt4'">
                <TabbarMt4 v-show="this.tabbarShow" active-color="#13b5b1" />
            </div>
        </div>
    </div>
</template>

<script>
    import "./assets/css/icon.css";

    import TabbarMt4 from "@/viewsmt4/TabbarMt4";
    import Tabbar from "@/views/Tabbar";

    import { mapState } from "vuex";
    export default {
        components: {
            TabbarMt4,
            Tabbar,
        },
        computed: {
            ...mapState({
                tabbarShow: (state) => state.tabbarShow,
                tabbarCurrentShow: (state) => state.tabbarCurrentShow,
            }),
        },
        watch: {
            // 监听路由的变化
            $route: {
                handler(to) {
                    this.$nextTick(() => {
                        if (this.tabbarCurrentShow == "Crm") {
                            document.title = "Mobile Crm";
                        } else {
                            document.title = "Mobile Trader";
                        }
                    });
                },
                deep: true,
                immediate: true,
            },
        },
        data() {
            return {
                //showTabbar: "Mt4"
            };
        },
        mounted() {
            //var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            //(function () {
            //    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            //    s1.async = true;
            //    s1.src = 'https://embed.tawk.to/61731bb986aee40a5737ed49/1fikq8k57';
            //    s1.charset = 'UTF-8';
            //    s1.setAttribute('crossorigin', '*');
            //    s0.parentNode.insertBefore(s1, s0);
            //})();
        }
    };
</script>

<style>
    #app {
        height: 100%;
        max-width: 750px;
        margin: auto;
        overflow-x: hidden;
        max-width: 750px;
        margin: 0 auto;
    }
    /* 有导航栏的高度 */
    .content-height {
        height: calc(100vh - 50px);
    }
    /* 无导航栏高度 */
    .page-height {
        height: 100%;
    }

    .van-tabs__content {
        height: inherit;
    }

    .page {
        margin-bottom: 0.1rem;
    }

    /**K-Line-Chart*/

    .k-line-chart-container {
        display: flex;
        flex-direction: column;
        margin: 0;
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 440px;
        padding: 16px 6px 16px 16px;
    }

    .k-line-chart-title {
        margin: 0;
        /* color: #E6E8EA;*/
        padding-bottom: 10px;
    }

    .k-line-chart {
        display: flex;
        flex: 1;
    }

    .k-line-chart-menu-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        font-size: 12px;
        /*color: #929AA5;*/
    }

        .k-line-chart-menu-container button {
            cursor: pointer;
            /*background-color: #2196F3;*/
            border-radius: 2px;
            margin-right: 8px;
            height: 24px;
            line-height: 26px;
            padding: 0 6px;
            font-size: 12px;
            /*color: #fff;*/
            border: none;
            outline: none;
        }

    ::-webkit-scrollbar {
        width: 0;
        display: none;
    }

    #app .van-nav-bar .van-icon {
        color: #333;
    }

    .van-safe-area-bottom {
        height: 50px;
    }

    #app .van-nav-bar {
        background-color: #13b5b1;
    }

        #app .van-nav-bar .van-icon {
            color: #fff !important;
        }

        #app .van-nav-bar .van-nav-bar__title {
            color: #fff;
        }

        #app .van-nav-bar .van-nav-bar__text {
            color: #fff;
        }
</style>
