import router from "@/router";
import { Toast } from "vant";

export default {
    /**
     * 退出登录
     */
    logout(state) {
        state.token = null;
        // 清除token
        localStorage.removeItem('token');
        localStorage.removeItem('tokenMt4');
        localStorage.removeItem('userinfo');
        localStorage.removeItem('mt4userinfo');
        localStorage.removeItem('accountinfo');
        localStorage.removeItem('email');
        localStorage.removeItem('exits');
        localStorage.removeItem('addArr');
        localStorage.removeItem('odd');
        localStorage.removeItem('chartSymbol');
        localStorage.removeItem('getSymbols');
        // 跳转到登录页面
        router.replace('/')
            .catch(err => console.warn(err));
        //Toast('退出成功')
    },

    /**
     * 登录
     * @param state
     * @param token
     */
    login(state, data) {
        state.token = data.token;
        state.userinfo = data.userinfo;
        localStorage.setItem('token', data.token);
        localStorage.setItem('userinfo', JSON.stringify(data.userinfo));
        //localStorage.setItem('refreshToken', data.refreshToken);
        // router.replace('/')
        //     .catch(err => console.warn(err));
        //Toast('登录成功');
    },

    loginMt4(state, data) {
        state.tokenMt4 = data.token;
        state.mt4userinfo = data.userinfo;
        localStorage.setItem('tokenMt4', data.token);
        localStorage.setItem('mt4userinfo', JSON.stringify(data.userinfo));
        //Toast('登录成功');
    },

    updateActive(state, data) {
        state.userinfo = data;
        localStorage.setItem('userinfo', JSON.stringify(data));
    },

    /**
     * 切换tabbar显示
     * @param state
     * @param booleanVal
     */
    toggleTabbarShow(state, booleanVal) {
        state.tabbarShow = booleanVal;
    },
    toggleTabbarCurrentShow(state, tabbarName) {
        state.tabbarCurrentShow = tabbarName;
    },
    /**
     * 更新手机号
     * @param state
     * @param email
     */
    updateEmail(state, email) {
        state.email = email;
        localStorage.setItem("email", email);
    },

    /**
     * 更新token
     * @param state
     * @param token
     */
    updateToken(state, data) {
        state.token = data.token;
        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
    },

    /**
     *
     * @param state
     * @param token
     * @param refreshToken
     */
    deleteToken(state) {
        state.token = null;
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userinfo");
    },

    deleteMt4Token(state) {
        state.tokenMt4 = null;
        localStorage.removeItem("tokenMt4");
        localStorage.removeItem("mt4userinfo");
    },

    addstock(state, data) {
        state.addStock.unshift(data);
    },


    setKLineTime(state, data) {
        state.klineTime = data;
    },

    changeBars(state, data) {
        state.headTitle = data;
    },

    setKLineType(state, data) {
        state.klineType = data;
    },

};


