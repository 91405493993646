export default {
    app: {
        login: '登錄',
        language: "語言",
        accountpwdlogin: "賬號密碼登錄",
        pleaseinputaccount: "請輸入賬號",
        pleaseinputpassword: "請輸入密碼",
        forgetpassword: "忘記了?",
        refundpassword: "找回密碼",
        noaccount: "沒有帳戶",
        registernow: "立即註冊",
        loginemailorlogin: "電子郵件/賬號",
        sildesuccesstext: "驗證通過！",
        sildefailtext: "驗證失敗，請重試",
        slidertext: "拖動滑塊完成拼圖",
        pleaseinputrepassword: "請再次輸入密碼",
        clickLoadMore: '點擊加載更多',
        noMoreData: '沒有更多了',
        more: '更多',
        account: '账号',
        delete: "删除",
        moreDetail: '更多详情',
        transactionignal: '交易訊號',
        switchAccount: '切換帳號',
        myCard: '我的卡',
        improve: '請完善銀行卡資訊',
        recomment: "推薦",
        binding: "添加",
        bind: "綁定其他銀行卡",
        common: "共",
        zhang: "張",
        add: "添加",
        promote: "促進",
        security: "安全防護",
        photograph: "拍照",
        photo: "手機相册",
        not: '可不填',
        emailErr: "請檢查您的輸入，然後重試!",
        email: "不正確的電子郵寄地址格式",
    },
    closePositionsFirst: "請先平倉!",
    hasSubscription: "信號賬戶有跟單用戶!",
    uploadFileRangeSize: '文件大小不超過3MB',
    sumProfit: "總收益",
    netprofitincludefee: "淨利潤（包含費用）",
    "pleaseinputsinglaname": "請輸入信號名稱",
    "pleaseinputpercentagesignalcom": "請輸入信號傭金",
    "pleaseinputpercentageparentcom": "請輸入推薦人傭金",
    commission: "佣金",
    "Status": "狀態",
    "ordernumber": "訂單號",
    "sendemailsuccess": "發送成功",
    "sendemailfailure": "發送失敗，請稍後再試",
    "accountdisabled": "帳號被禁用",
    "emailnotexisting": "郵箱不存在",
    "alreadysubmitunscribe": "已經提交了撤離申請",
    "createfailpleasetryagainlater": "操作失敗，請稍後再試",
    "alreadnotcopy": "已經不再跟單",
    "directly": "直屬",
    "secondary": "從屬",
    "parent": "推薦人",
    "singalhaveoption": "信號源有持倉",
    "android": "安卓",
    "ios": "蘋果",
    "windows": "桌面",
    referralLink: '推廣鏈接',
    clickToCopy: '點擊複製',
    //start
    resetCrmPwd: '重置密碼',
    pleaseConfirmNewPwdBefore: '請為',
    pleaseConfirmNewPwdAfter: '設置新的登錄密碼',
    retrievepwd: "找回密碼",
    resetpwdlinksendtoyouremail: "修改密碼鏈接將會發至您的註冊郵箱，請注意查收!",
    pleaseinputemail: "請輸入郵箱",
    notrightformatemail: "郵箱格式不正確",
    ok: "確認",
    wrongnameorpassword: "錯誤的賬號或密碼",
    clicktologinroregister: "點擊登錄/註冊",
    completematerial: "完善資料",
    personalinformation: "個人信息",
    identityinformaiton: "證件信息",
    perfectsuccess: "完善成功",
    phone: "電話",
    areacode: "區號",
    pleaseinputphone: "請輸入號碼",
    country: "國籍",
    pleaseselectcountry: "請選擇國家",
    province: "省份",
    pleaseselectprovince: "請選擇省份",
    city: "城市",
    pleaseselectcity: "請選擇城市",
    username: "用戶名",
    address: "地址",
    pleaseinputaddress: "請輸入地址",
    submit: "提交",
    cancel: "取消",
    front: "正面",
    back: "反面",
    proofofaddress: "地址證明",
    opentradeaccount: "開交易賬戶",
    openagentaccount: "開代理賬戶",
    tohome: "返回主頁",
    uploadsuccess: "上傳成功",
    uploading: "上傳中",
    pleaseuploadpicture: "請上傳圖片",
    pleaseinputprovinceorcity: "請填寫省份或城市",
    openaccount: "開戶",
    churujin: "出/入金",
    copyorder: "跟單",
    wallet: "錢包",
    mycustomers: "我的下級",
    mydocument: "我的證件",
    iwanttopromote: "我要推廣",
    downloadtradeclient: "下載交易端",
    myapplication: "我的申請",
    applycopy: "申請跟單",
    copyapply: "跟單申請",
    mycopy: "我的跟單",
    myapplycopy: "我的申請",
    applysignal: "申請信號",
    signalapply: "信號申請",
    mysignal: "我的信號",
    notlogin: "您還未登錄？",
    areyousurethatlogout: "確定退出？",
    toback: "返回",
    avater: "頭像",
    email: "郵箱",
    unbound: "未綁定",
    pleaseinputphone: "請填寫電話號碼",
    success: "操作成功",
    copyapply: "跟單申請",
    tips: "提示",
    notrepeatandnotsignal: "此賬號不能重複跟單，且不是信號源!",
    signalname: "信號源",
    pleaseselect: "請選擇",
    copyaccount: "跟單賬號",
    copytype: "跟單方式",
    copyvalue: "跟單值",
    copydirection: "跟單方向",
    pleaseinput: "請輸入",
    reverse: "反向",
    positive: "正向",
    percentage: "百分比",
    fixedlot: "固定手數",
    equityratio: "淨值比例",
    percentageholder: "請輸入(%)",
    fixedlotholder: "請輸入固定手數，最小0.01",
    equityratioholder: "禁止輸入",
    copypercentagemustgreaterzero: "跟單百分比必須大於0",
    copyvolumesamllest: "固定手數最小0.01",
    createfailpleasetryagainlater: "操作失敗請稍後再試",
    systemerror: "系統錯誤",
    createsuccess: "操作成功",
    signalname: "信號源",
    signalstate: "信號源狀態",
    copystate: "跟單狀態",
    clicktoloadmore: "點擊加載更多",
    nomore: "沒有更多了",
    SignalCom: "信號佣金",
    ParentCom: "推薦人佣金",
    profitShareLogin: "分紅賬戶",
    unavailable: "不可用",
    normal: "可用",
    failure: "失敗",
    applying: "申請中",
    success: "成功",
    disable: "已禁用",
    deleted: "已刪除",
    areyousureleavecopy: "確定取消跟單？",
    lots: "手",
    none: "無",
    pleaseselect: "請選擇",
    login: "賬戶",
    pleaseinputpercentage: "請輸入(%)",
    loginnoeempty: "賬號不許為空",
    signalnamenotempty: "信號名字不許為空",
    signalcommduringzerotoonehundred: "信號源佣金應在0到100%",
    parentcommduringzerotoonehundred: "推薦人佣金應在0到100%",
    thesumcommmusetlessonehundred: "佣金總和不得大於100%",
    revoke: "撤離",
    alllogins: "全部賬號",
    applyforunsubscribe: "申請撤離",
    areyousuredisalbesiganl: "確定禁用此信號？",
    areyousureactivethesiganl: "確定啟用此信號?",
    areyousuredeletethesignal: "確定刪除此信號?",
    disabled: "禁用",
    applying: "處理中",
    cooperationsignal: "合作信號",
    freesignal: "江湖信號",
    AllProfit: "總盈利",
    WeekProfit: "週盈利",
    MonthProfit: "月盈利",
    ProfitPercent: "盈利係數",
    Name: "信號源",
    CreateTime: "創建時間",
    banlancetrend: "資金走勢圖($)",
    signaldetail: "信號詳情",
    Balance: "餘額",
    Equity: "淨值",
    Deposit: "總入金",
    Withdrawal: "總出金",
    AllProfit: "盈利",
    Profit2: "虧損",
    Profit: "淨利潤(包含費用)",
    ProfitPercent: "盈利係數",
    Volume: "交易量",
    TicketCount: "訂單數量",
    TicketBuy: "多單數量",
    TicketSell: "空單數量",
    ProfitCount1: "盈利訂單",
    ProfitCount2: "虧損訂單",
    tradesummary: "交易概況",
    TradeNum: "總交易量",
    TradeCycle: "交易天數",
    MaxDrawdown: "最大回撤",
    WeekProfit: "近一周收益",
    MonthProfit: "近一月收益",
    AllProfit: "總收益",
    Copy: "立即跟單",
    tokenexpried: "認證過期，請重新登錄",
    termail: "交易終端",
    download: "下載",
    //end

    sort: '排序',
    sortByTime: '時間',
    sortByAmount: '金額',
    sortByProfit: '利潤',
    sortByDeposit: '入金',
    sortByWithdrawal: '出金',
    allAccount: '全部賬號',

    notLoggenIn: '您還未登錄',
    confirmExit: '確定退出系統?',
    developing: '功能暫未上線~',
    dataerror: "請檢查您的輸入並重試！",
    success: "操作成功!",
    networkError: "網絡錯誤",
    codeSendSuccess: "驗證碼發送成功!",
    codeSendFailed: "驗證碼發送失敗!",
    tryAgainLater: "請稍後在嘗試!",
    verificationCode: "驗證碼",
    sendVerificationCode: "發送驗證碼",
    pleaseInputVerificationCode: "請輸入驗證碼",
    loading: "處理中",
    commonTools: "常用工具",
    changePassword: "修改密碼",
    customerManagement: '客戶管理',
    forbiddenToLogin: "禁止登入",
    Register: {
        register: "註冊",
        hasAccount: "已有賬戶",
        loginNow: "立即登錄",
        emailFormatError: "郵箱格式不正確",
        pwdStrongError: "密碼強度不夠",
        mailAlreadyExists: "該賬戶已註冊,請直接登錄!",
        registerFailed: "註冊郵件發送失敗,請聯繫客服!",
        activeExpired: "驗證已過期,請重新發送郵件激活",
        activeSuccess: "激活成功",
        userEmail: "郵箱",
        userName: "姓名",
        userPassword: "密碼",
        userPasswordAgain: "確認密碼",
        userAgent: "推薦人",
        registerSuccess: "註冊成功,請前往郵箱進行激活!",
        pwdNotMatch: "兩次輸入的密碼不一致!",
    },
    NewAccount: {
        accountApplication: "申請賬戶",
        verifyExpired: "驗證失敗",
        alreadyMaxCount: "賬戶數量已達到上限",
        loginTypeError: "錯誤的賬戶類別",
        loginOutOfRange: "賬戶超出範圍!",
        loginOpenSuccess: "賬戶開設成功!",
        loginApplicationSuccess: "開戶申請提交成功!",
        loginApplicationFailed: "開戶申請提交失敗!",
        accountType: "賬戶類型",
        chooseAccountType: "選擇賬戶類型",
        leverage: "槓桿",
        chooseLeverage: "選擇槓桿",
        submitApplication: "提交申請",
        tradingAccount: '交易賬戶',
        agentAccount: '代理賬戶',
    },
    Funds: {
        deposit: "入金",
        withdrawals: "取款",
        transfer: "轉賬",
        login: "賬戶",
        chooseLogin: "選擇賬戶",
        submitApplication: "提交申請",
        withdrawalsMoneyUsd: "取款金額($)",
        pleaseInputWithdrawalsMoneyUsd: "請輸入取款金額",
        depositMoney: "入金金額",
        depositMoneyUsd: "入金金額",
        pleaseInputDepositMoney: "請輸入入金金額",
        pleaseInputDepositMoneyUsd: "入金金額($)",
        freeBalance: "可用餘額",
        currency: "貨幣選擇",
        pleaseChooseCurrency: "請選擇貨幣",
        bankInfo: "銀行信息",
        pleaseChooseBankInfo: "請選擇銀行信息",
        balanceError: "獲取失敗,請稍後在嘗試!",
        noBankCard: "請添加收款銀行",
        notEnoughMoney: "可用餘額不足",
        subscribersOnlyWithdrawOnSunday: "跟單用戶請在每週週日提交出金申請!",
        dailyWithdrawalsLimit: "取款達到單日最高限額!",
        applicationSuccess: "申請提交成功!",
        applicationError: "申請提交失敗!",
        transAmount: "轉賬金額",
        pleaseInputTransAmount: "請輸入轉賬金額",
        transLogin: "轉賬賬戶",
        pleaseInputTransLogin: "請輸入轉賬賬戶",
        bankCardUsdtBtc: "錢包",
        walletAddress: '收款地址',
        bankCard: '銀行卡',
        edit: '編輯',
        bankCardAdd: "新增銀行信息",
        bankCardEdit: "編輯銀行信息",
        bankName: "銀行名稱",
        pleaseInputBankName: "請輸入銀行名稱",
        bankCardId: "銀行卡號",
        pleaseInputBankCardId: "請輸入銀行卡號",
        bankUsdtAdd: "新增USDT地址",
        bankUsdtEdit: "編輯USDT地址",
        bankCardUsdt: "USDT地址",
        pleaseInputBankCardUsdt: "請輸入USDT收款地址",
        bankBtcAdd: "新增BTC地址",
        bankBtcEdit: "編輯BTC地址",
        bankCardBtc: "BTC地址",
        pleaseInputBankCardBtc: "請輸入BTC收款地址",
        swiftCode: "SWIFT Code",
        pleaseInputSwiftCode: "SWIFT Code(可空)",
        branchName: "分行名稱",
        pleaseInputBranchName: "請輸入分行名稱",
        bankAddress: "銀行地址",
        pleaseInputBankAddress: "請輸入銀行地址",
        payChannel: "支付通道",
        pleaseInputPayChannel: "請選擇支付通道",
        doNotRepeatUpload: "請勿重複上傳",
        errorImageFormat: "請上傳圖片",
        uploadCert: "上傳憑證",
        pleaseChoosePaymentChannel: '請選擇一個支付通道',
        processing: '處理中',
        refused: '已拒絕',
        completed: '已完成',
        viewCert: '查看憑證',
        minWithdrawal: "最低出金金額:",
        maxMinDeposit: '支付金額:',
        usdtTypeName: '鏈名稱',
        totalAmount: '全部金額',
        withdrawal: '提现',
        recharge: '充值',
        bankAccount: '銀行帳戶',
    },
    Leverage: {
        changeLeverage: '調整槓桿',
        leverage: '槓桿',
        leverageOld: '原槓桿',
        leverageNew: '新槓桿',
        pleaseChooseLeverageNew: '請選擇槓桿',
    },
    ChangePwd: {
        changeCrmPwd: '修改CRM登錄密碼',
        changeMt4Pwd: '修改MT5密碼',
        mainPassword: '交易密碼',
        investorPassword: '只讀密碼',
        userName: '用戶名',
        password: '密碼',
        pleaseInputPassword: '請輸入密碼(至少8位)',
        passwordAgain: '確認密碼',
        pleaseInputPasswordAgain: '請輸入確認密碼(至少8位)',
        pwdChangeSuccess: '密碼修改成功,請重新登錄',
        passwordType: '密碼類型',
        pleaseChoosePasswordType: '請選擇密碼類型',
        pwdLengthError: "密碼長度8至18位",
        validationFailure: '驗證失敗,請重新嘗試',
        setPasswordSuccess: '密碼重置成功'
    },
    AccountInfo: {
        userList: '用戶列表',
        leverage: "槓桿",
        group: "組",
        equity: '淨值',
        balance: '餘額',
        credit: '信用',
        freeMargin: '可用保證金',
        regDate: '開戶日期'
    },
    Menu: {
        home: '首頁',
        report: '報表',
        signal: '信號',
        mine: '我的',
        profile: '個人信息',
        accountInfo: '賬戶信息',
        bankInfo: '銀行信息',
        idInfo: '證件信息',
        accountManagent: '賬戶管理',
        updateCrmPwd: 'CRM密碼',
        updateMt4Pwd: '交易賬戶密碼',
        changeLeverage: '調整槓桿',
        fundsManagent: '出入金',
        deposit: "入金",
        withdrawals: "取款",
        transfer: "轉賬",
        fundsHistory: '賬戶流水',
        reportCenter: '報表中心',
        closeReport: '平倉報告',
        positionReport: '持倉報告',
        depositReport: '業績報告',
        creditReport: '信用報告',
        commissionReport: '佣金報告',
        commissionTotal: '佣金統計',
        summaryReport: '匯總報告',
        community: '跟單社區',
        myCopy: '跟單社區',
        mySignal: '跟單社區',
        cancel: '取消',
        totalBalance: '餘額',
        totalWithdrawal: '出金',
        totalDeposit: '入金',
        logOut: '退出',
        aboutUs: '關於我們',
        myApplyApplication: '我的申請',
        openAccount: '開戶',
        depositOrWithdrawal: '出/入金',
        copyTrade: '跟單',
        wallet: '錢包',
        underling: '我的客戶',
        credentials: '我的證件',
        myLink: '我要推廣',
        downloadMetatrade: '下載交易端',
        createSignal: '申請信號',
        mySignal: '我的信號',
        myApplySignalLog: '我的申請',
        aplyForCopy: '申請跟單',
        myCopies: '我的跟單',
        myApplyCopyLog: '我的申請',
        toTrading: "我要交易",
        performance: "业绩报表",
    },
    Report: {
        commissionTotal: '佣金統計',
        volume: '交易量',
        balance: '餘額',
        swaps: '庫存費',
        taxes: '稅金',
        credit: '信用',
        depossitTotal: '入金',
        withdrawalTotal: '出金',
        takeProfit: '止盈',
        stopLoss: '止損',
        charges: '手續費',
        commission: '佣金',
        commissionAgent: '代理佣金',
        shareProfit: '分紅',
        signalCommission: '信號源佣金',
        totalCommission: '總佣金',
        pleaseChooseLogin: '請選擇賬戶'
    },
    "alreadywassignal": "已經是信號源",
    "loginnotexist": "帳號不存在",
    "alreadycopyingsignal": "正在跟單",
    "alreadyfollowthesignal": "已經在跟當前信號",
    "thisloginsubmitsignalapply": "已經提交信號申請",
    "alreadysubmitfolowapply": "已經提交跟單申請",
    Mt4ApiMenu: {
        quote: "行情",
        chart: "圖表",
        trade: "交易",
        history: "歷史",
        settings: "設置",
        agreement: "用戶協議",
        privacyPolicy: "隱私政策",
        aboutUs: "關於我們",
        loginToCrm: "管理端登錄",
        loginToMt4: "交易端登錄"
    },
    Mt4ApiTrade: {
        margin: "預付款",
        marginFree: "可用預付款",
        marginLevel: "預付款比率",
        profit: "利潤",
        selectDate: "選擇日期",
        stopLoss: '止損',
        takeProfit: '停止',
        pendingPrice: '價格',
        pendingExpDate: '期限',
        ticket: '訂單',
        delete: '删除',
        addSymbol: "添加自選",
        spread: "點差",
        high: "最高",
        low: "最低",
        showAll: "查看全部",
        searchSymbol: "搜索交易品種",
        tradeWarning: "注意：市價交易模式下的實際成交價格，可能會​​和請求價格有一定差異！",
        newAccount: "新賬戶",
        openNewAccount: "註冊新賬戶",
        openNewAccountDesc: "選擇經紀人並註冊一個帳戶以學習交易並測試您的策略",
        loginWithNewAccount: "登錄一個已有賬戶",
        loginWithNewAccountDesc: "使用您的登錄名/密碼連接您選擇的交易商",
        account: "交易賬戶",
        password: "密碼",
        toCrm: "會員中心",
        positionOrder: '持倉',
        pendingOrder: '掛單',
        closeOrder: '平倉',
        deleteOrder: '刪除',
        modifyOrder: '修改',
        trade: '交易',
        buyLimit: '限價買入',
        buyStop: '買入止損',
        sellLimit: '限價賣出',
        sellStop: '賣出止損',
        marketExecution: '市價執行',
        sellByMarket: '市價賣出',
        buyByMarket: '市價買入'
    },
    KLineChart: {
        time: "時間",
        open: "開",
        close: "收",
        high: "高",
        low: "低",
        volume: "成交量",
        candleSolid: "蠟燭實心",
        candleSStroke: "蠟燭空心",
        candleUpStroke: "蠟燭漲空心",
        candleDownStroke: "蠟燭跌空心",
        candleOhlc: "OHLC",
        candleArea: "面積圖",
    },
    LoginIsError: "登錄失敗",
    LogoutIsError: "登出失敗",
    DataIsNULL: "數據為空",
    AcceptRequestError: "接受請求失敗",
    InvalidSlOrTp: "無效止盈與止損",
    CheckStopsAndExpirationError: "無效止盈與止損",
    CheckTradeRequestError: "請求失敗",
    GetKdataError: "獲得K線數據失敗",
    GetSymbolByCurrencyError: "獲得品種失敗",
    GetOpenOrderByTicketError: "獲得訂單失敗",
}