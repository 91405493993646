import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Config from "@/utils/baseConfig";

Vue.use(VueRouter);


// 路由懒加载
const Home = () => import('../views/home/Home.vue');
const Travel = () => import('../views/travel/Travel.vue');
const community = () => import('../views/community/News.vue');
const News = () => import('../views/news/News.vue');
const Mine = () => import('../views/mine/Mine.vue');
const Login = () => import(/* webpackChunkName: "group-login" */ '../views/login/Login.vue');
const LoginByCode = () => import(/* webpackChunkName: "group-login" */ '../views/login/LoginByCode.vue');
const LoginByPwd = () => import(/* webpackChunkName: "group-login" */ '../views/login/LoginByPwd.vue');
const LoginCrm = () => import(/* webpackChunkName: "group-login" */ '../views/login/LoginCrm.vue');
const RetrievePwd = () => import(/* webpackChunkName: "group-login" */ '../views/login/RetrievePwd.vue');
const Register = () => import(/* webpackChunkName: "group-login" */ '../views/login/Register.vue');
const SetNewPwd = () => import(/* webpackChunkName: "group-login" */ '../views/login/SetNewPwd.vue');
const member = () => import(/* webpackChunkName: "group-login" */ '../views/member/index.vue');
const NewAccount = () => import(/* webpackChunkName: "group-login" */ '../views/member/NewAccount.vue');
const init = () => import(/* webpackChunkName: "group-login" */ '../views/member/init.vue');
const Deposit = () => import(/* webpackChunkName: "group-login" */ '../views/funds/Deposit.vue');
const Transfer = () => import(/* webpackChunkName: "group-login" */ '../views/funds/Transfer.vue');
const Withdrawals = () => import(/* webpackChunkName: "group-login" */ '../views/funds/Withdrawals.vue');
const profile = () => import(/* webpackChunkName: "group-login" */ '../views/member/Profile.vue');
const BankInfo = () => import(/* webpackChunkName: "group-login" */ '../views/funds/BankInfo.vue');
const BankAdd = () => import(/* webpackChunkName: "group-login" */ '../views/funds/BankAdd.vue');
const BankEdit = () => import(/* webpackChunkName: "group-login" */ '../views/funds/BankEdit.vue');
const Address = () => import(/* webpackChunkName: "group-login" */ '../views/member/SczjGood.vue');
const History = () => import(/* webpackChunkName: "group-login" */ '../views/funds/History.vue');
const Payment = () => import(/* webpackChunkName: "group-login" */ '../views/funds/Payment.vue');
const Underling = () => import(/* webpackChunkName: "group-login" */ '../views/funds/Underling.vue');
const terminal = () => import(/* webpackChunkName: "group-login" */ '../views/member/terminal.vue');
const share = () => import(/* webpackChunkName: "group-login" */ '../views/member/share.vue');
const Business = () => import(/* webpackChunkName: "group-login" */ '../views/report/Business.vue');
const Position = () => import(/* webpackChunkName: "group-login" */ '../views/report/Position.vue');
const OrdersHistory = () => import(/* webpackChunkName: "group-login" */ '../views/report/OrdersHistory.vue');
const Commission = () => import(/* webpackChunkName: "group-login" */ '../views/report/Commission.vue');
const Credit = () => import(/* webpackChunkName: "group-login" */ '../views/report/Credit.vue');
const Bonus = () => import(/* webpackChunkName: "group-login" */ '../views/report/Bonus.vue');
const Summary = () => import(/* webpackChunkName: "group-login" */ '../views/report/Summary.vue');

const applycopy = () => import(/* webpackChunkName: "group-login" */ '../views/signal/applycopy.vue');
const applysignal = () => import(/* webpackChunkName: "group-login" */ '../views/signal/applysignal.vue');
const mycopy = () => import(/* webpackChunkName: "group-login" */ '../views/signal/mycopy.vue'); //
const mysignal = () => import(/* webpackChunkName: "group-login" */ '../views/signal/mysignal.vue');
const applyingcopy = () => import(/* webpackChunkName: "group-login" */ '../views/signal/applyingcopy.vue');
const applyingsignal = () => import(/* webpackChunkName: "group-login" */ '../views/signal/applyingsignal.vue');


const CrmPwd = () => import(/* webpackChunkName: "group-login" */ '../views/member/CrmPwd.vue');
const Mt4Pwd = () => import(/* webpackChunkName: "group-login" */ '../views/member/Mt4Pwd.vue');
const Leverage = () => import(/* webpackChunkName: "group-login" */ '../views/member/Leverage.vue');

const Language = () => import(/* webpackChunkName: "group-login" */ '../views/member/Language.vue');
const trading = () => import(/* webpackChunkName: "group-login" */ '../views/trading/index.vue');

/**
 * 
 * @returns v0.0.2(2021/9/28)
 */
const Setting = () => import('@/components/crm/Setting.vue');
const MyCard = () => import('@/components/crm/MyCard.vue');
const Account = () => import('@/components/crm/Account.vue');
const Trent = () => import('@/components/crm/Trent.vue');
const AccountHome = () => import('@/views/home/Account.vue');
const Open = () => import('@/components/crm/Open.vue');
const Out = () => import('@/components/crm/Out.vue');
const Enter = () => import('@/components/crm/Enter.vue');
const Documentary = () => import('@/components/crm/Documentary.vue');
const Singal = () => import('@/components/crm/Singal.vue');
const Change_pwd = () => import('@/components/crm/ChangePwd.vue');
const Balance_crm = () => import('@/components/crm/Balance.vue');
const More_crm = () => import('@/components/crm/More.vue');




const LoginMt4 = () => import('../viewsmt4/login/LoginMt4.vue');
const Settings = () => import('../viewsmt4/settings/Index.vue');
const Quotes = () => import('../viewsmt4/quotes/Index.vue');
const ChartKline = () => import('../viewsmt4/chart/Index.vue');
const Chart = () => import('../viewsmt4/chart/TradingViews.vue');
const Mt4History = () => import('../viewsmt4/history/Index.vue');
const Trade = () => import('../viewsmt4/trade/Index.vue');
const NewOrder = () => import('../viewsmt4/trade/NewOrder.vue');
const PendingOrder = () => import('../viewsmt4/trade/PendingOrder.vue')
const AllSymbol = () => import('../viewsmt4/quotes/AllSymbol.vue');
const ClassSymbol = () => import('../viewsmt4/quotes/ClassSymbol.vue');
const CommonSymbol = () => import('../viewsmt4/quotes/CommonSymbol.vue');
const ClassTab = () => import('../viewsmt4/quotes/ClassTab.vue');
const Symbols = () => import('../viewsmt4/trade/Symbols.vue');
const ChartSettings = () => import('../viewsmt4/settings/ChartSettings.vue');
const NewMt4Account = () => import('../viewsmt4/settings/NewAccount.vue');
const ChangeAccount = () => import('../viewsmt4/settings/ChangeAccount.vue');
const AboutUs = () => import('../viewsmt4/settings/AboutUs.vue');



const routes = [
    {
        path: '/',
        redirect: Config.terminal == 0 ? '/LoginCrm' : '/LoginMt4'
        //redirect: '/LoginMt4'
        //redirect: '/loginByPwd'
    },
    {
        path: '/trading',
        component: trading
    },
    {
        path: '/News',
        component: News
    },
    {
        path: '/home',
        component: Home,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/travel',
        component: Travel,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/community',
        component: community
    },
    {
        path: '/mine',
        component: Mine
    },
    {
        path: '/login',
        component: Login,
        meta: {
            keepAlive: true,
            requireAuth: false
        }
    },
    {
        path: '/loginByCode',
        name: '/loginByCode',
        component: LoginByCode,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/loginByPwd',
        name: '/loginByPwd',
        component: LoginByPwd,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/LoginCrm',
        name: '/LoginCrm',
        component: LoginCrm,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/retrievePwd',
        name: '/retrievePwd',
        component: RetrievePwd,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/Register',
        name: '/Register',
        component: Register,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/SetNewPwd',
        name: '/SetNewPwd',
        component: SetNewPwd,
        meta: {
            requireAuth: false
        }
    },
    {
        path: '/member',
        name: '/member',
        component: member,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member/NewAccount',
        name: '/member/NewAccount',
        component: NewAccount,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/init',
        name: '/init',
        component: init,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/Deposit',
        name: '/funds/Deposit',
        component: Deposit,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/Withdrawals',
        name: '/funds/Withdrawals',
        component: Withdrawals,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/Transfer',
        name: '/funds/Transfer',
        component: Transfer,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member/profile',
        name: '/member/profile',
        component: profile,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/BankInfo',
        name: '/funds/BankInfo',
        component: BankInfo,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/BankAdd',
        name: '/funds/BankAdd',
        component: BankAdd,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/BankEdit',
        name: '/funds/BankEdit',
        component: BankEdit,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member/Address',
        name: '/member/Address',
        component: Address,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/History',
        name: '/funds/History',
        component: History,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/terminal',
        name: '/terminal',
        component: terminal,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/share',
        name: '/share',
        component: share,
        meta: {
            requireAuth: true
        }
    }, {
        path: '/funds/Payment',
        name: '/funds/Payment',
        component: Payment,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/funds/Underling',
        name: '/Underling',
        component: Underling,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/signal/applycopy',
        name: '/signal/applycopy',
        component: applycopy,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/signal/applysignal',
        name: 'applysignal',
        component: applysignal,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/signal/mycopy',
        name: 'mycopy',
        component: mycopy,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/signal/mysignal',
        name: 'mysignal',
        component: mysignal,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/report/Business',
        name: '/report/Business',
        component: Business,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/report/OrdersHistory',
        name: '/report/OrdersHistory',
        component: OrdersHistory,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/report/Position',
        name: '/report/Position',
        component: Position,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/report/Commission',
        name: '/report/Commission',
        component: Commission,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/report/Credit',
        name: '/report/Credit',
        component: Credit,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/report/Bonus',
        name: '/report/Bonus',
        component: Bonus,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/report/Summary',
        name: '/report/Summary',
        component: Summary,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/applyingcopy',
        name: '跟单申请',
        component: applyingcopy,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/applyingsignal',
        name: '/信号申请',
        component: applyingsignal,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member/CrmPwd',
        name: '/member/CrmPwd',
        component: CrmPwd,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member/Mt4Pwd',
        name: '/member/Mt4Pwd',
        component: Mt4Pwd,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member/Leverage',
        name: '/member/Leverage',
        component: Leverage,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/member/Language',
        name: '/member/Language',
        component: Language,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/member/setting',
        name: 'Setting',
        component: Setting,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/member/bankinfo/card/:id',
        name: 'MyCard',
        component: MyCard,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/member/funds/Underling/accound',
        name: 'Account',
        component: Account,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/community/trent',
        name: 'Trent',
        component: Trent,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/home_account',
        name: 'Home_account',
        component: AccountHome,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/home_account/open',
        name: 'Open',
        component: Open,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/home_account/out',
        name: 'Out',
        component: Out,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/home_account/enter',
        name: 'Enter',
        component: Enter,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/home_account/documentary',
        name: 'Documentary',
        component: Documentary,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/home_account/singal',
        name: 'Singal',
        component: Singal,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/home_account/change_pwd',
        name: 'Change_pwd',
        component: Change_pwd,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/home_account/balance',
        name: 'Balance',
        component: Balance_crm,
        meta: {
            requireAuth: false,
        }
    },
    {
        path: '/home_account/more',
        name: 'More',
        component: More_crm,
        meta: {
            requireAuth: false,
        }
    },



    /*********************************Mt4 Api****************************************/
    {
        path: '/LoginMt4',
        name: '/LoginMt4',
        component: LoginMt4,
        meta: {
            mt4Auth: false
        },

    }, {
        path: '/Settings',
        name: '/Settings',
        component: Settings,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/Quotes',
        component: Quotes,
        children: [
            {
                path: '/',
                component: CommonSymbol,
                meta: {
                    mt4Auth: true
                }
            },
            {
                path: 'addstock',
                component: ClassSymbol,
                meta: {
                    mt4Auth: true
                }
            },
            {
                path: 'classsymbol',
                component: AllSymbol,
                meta: {
                    mt4Auth: true
                }
            },
            {
                path: 'classtab',
                component: ClassTab,
                meta: {
                    mt4Auth: true
                }
            },
        ],
        meta: {
            mt4Auth: true
        }
    },

    {
        path: '/Trade',
        name: '/Trade',
        component: Trade,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/Chart',
        name: '/Chart',
        component: Config.chart == 1 ? Chart : ChartKline,
        meta: {
            mt4Auth: true
        }
    },
    // {
    //     path: '/ChartKline',
    //     name: '/ChartLine',
    //     component: ChartKline,
    //     meta: {
    //         mt4Auth: true
    //     }
    // },
    {
        path: '/History',
        name: '/History',
        component: Mt4History,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/NewOrder',
        name: '/NewOrder',
        component: NewOrder,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/pendingorder',
        name: '/pendingorder',
        component: PendingOrder,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/Symbols',
        name: '/Symbols',
        component: Symbols,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/chartsettings',
        name: '/chartsettings',
        component: ChartSettings,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/newmt4account',
        name: '/newmt4account',
        component: NewMt4Account,
        meta: {
            mt4Auth: true
        }
    },

    {
        path: '/changeaccount',
        name: '/changeaccount',
        component: ChangeAccount,
        meta: {
            mt4Auth: true
        }
    },
    {
        path: '/about',
        name: '/about',
        component: AboutUs
    },
    //

];

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});


// router.beforeEach((to, from, next) => {
//     //console.log(store.state.token);
//     //console.log(to.meta.mt4Auth);
//     // 需要权限校验
//     if (to.meta.requireAuth && store.state.token === null) {
//         store.commit('toggleTabbarShow', false);
//         next('/loginByPwd');
//     }
//     if (to.meta.mt4Auth && store.state.tokenMt4 === null) {
//         store.commit('toggleTabbarShow', false);
//         next('/LoginMt4');
//     }
//     next();
// });

export default router
