import axios from 'axios'
import store from '@/store'
import QS from 'qs'
import { Toast } from "vant";
import { Notify } from 'vant';
import router from "@/router";
import { i18n } from '@/lang'
import baseConfig from '@/utils/baseConfig'


const instance = axios.create({
    baseURL: baseConfig.apiUrl,
    timeout: baseConfig.timeOut
});

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

instance.interceptors.request.use(
    config => {
        //debugger 
        const token = store.state.token;
        var lang = localStorage.getItem('lang') || 'en';
        localStorage.setItem('lang', lang);
        config.headers["Lang"] = lang;
        var currTab = store.state.tabbarCurrentShow;
        //console.log("request:" + currTab);
        if (currTab.toLowerCase() == 'crm') {
            switch (config.url.toLowerCase()) {
                case "/api/customer/login":
                case "/api/customer/retrievepassword":
                case "/api/customer/active":
                case "/api/customer/register":
                    // case "/api/sendcode/sendcode":
                    deleteCrmCache();
                    break;
                default:
                    if (token != null) {
                        config.headers.common['Authorization'] = "Bearer " + token;
                    }
                    break;
            }

        } else {
            switch (config.url.toLowerCase()) {
                case "/api/authorizemt4/authorize":
                    deleteMt4Cache();
                    break;
                default:
                    var tokenMt4 = store.state.tokenMt4;
                    if (tokenMt4 != null) {
                        config.headers.common['Authorization'] = "Bearer " + tokenMt4;
                    }
                    break;
            }
        }
        /*取消加载动画*/
        switch (config.url.toLowerCase()) {

            case "/api/trade/klinedata":
            case "/api/trade/klinetradingview":
                if (parseInt(config.data.space) === 1 || parseInt(config.data.space) === 3600) {
                    break;
                }
            default:
                 Toast.loading({ duration: 0, overlay: false, message: i18n.t('loading') });
                // Notify({ type: 'success', message: i18n.t('loading') });
                break;
                
        }

        if (config.data != undefined) {
            console.log(`Request Url: ${config.url}`);
            console.log(`Request Parameters: ${JSON.stringify(config.data)}`);
        }

        return config;

    },
    error => {
        //debugger
        console.log("request error:");
        console.warn(error);
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        Toast.clear();

        const res = response.data;
        console.log(`Response Status:${response.status}`);
        console.log(`Response:${JSON.stringify(res)}`);

        //debugger
        var currTab = store.state.tabbarCurrentShow;
        let { data } = response;
        if (currTab.toLowerCase() == "crm") {
            if (data.code === 401) {
                Toast(i18n.t("tokenexpried"));
                store.commit("deleteToken");
                store.commit("toggleTabbarShow", false);
                router.replace("/");
                return;
            }
            if (data.code === 400) {
                refreshToken(response);
                return Promise.resolve(response.data);
            }
            if (data.code !== 200) {
                // Toast(i18n.t(data.msg));
                return Promise.resolve(response.data);
            } else {
                //console.log(data.msg);
                return Promise.resolve(response.data);
            }
        }
        else {
            if (data.code === 401) {
                Toast(i18n.t("tokenexpried"));
                store.commit("deleteMt4Token");
                store.commit("toggleTabbarShow", false);
                router.replace("/loginmt4");
                return;
            }
            if (data.code === 400) {
                refreshToken(response);
                return Promise.resolve(response.data);
            }
            if (data.code !== 200) {
                Toast(data.msg);

                return Promise.resolve(response.data);
            } else {
                return Promise.resolve(response.data);
            }
        }

    },
    error => {
        //debugger
        console.log("response error:" + error.message);
        console.log("response error:" + error);
        var currTab = store.state.tabbarCurrentShow;
        if (currTab.toLowerCase() == "crm") {
            if (error.response.status === 401) {
                Toast(i18n.t("tokenexpried"));
                store.commit("deleteToken");
                store.commit("toggleTabbarShow", false);
                router.replace("/");
                return;
            }
            if (error.response.status === 500) {
                Toast("Server Error");
                return;
            }
            if (error.message === 'Network Error') {
                Toast("Network Error");
                return;
            }
            return Promise.reject(error);
        }
        else {
            if (error.response.status === 401) {
                Toast(i18n.t("tokenexpried"));
                store.commit("deleteMt4Token");
                store.commit("toggleTabbarShow", false);
                router.replace("/loginmt4");
                return;
            }
            if (error.response.status === 500) {
                Toast("Server Error");
                return;
            }
            if (error.message === 'Network Error') {
                Toast("Network Error");
                return;
            }
            return Promise.reject(error);
        }

    }
);

let isRefreshing = true;
function refreshToken(response) {
    if (isRefreshing) {
        refreshTokenRequest()
    }
    isRefreshing = false;
    const retryOriginalRequest = new Promise((resolve => {
        addSubscriber(() => {
            let config = response.config
            resolve(http.request(config.url, config.method, config.params))
        });
        return retryOriginalRequest;
    }))
}

function refreshTokenRequest() {
    //const refreshToken = localStorage.getItem("refreshToken");
    //let params = {
    //    refreshToken
    //}
    //instance.post('/login/token/refresh', QS.stringify(params)).then(res => {
    //    store.commit("updateToken", res.data)
    //    onAccessTokenFetched();
    //    isRefreshing = true;
    //})
}


function deleteMt4Cache() {
    localStorage.removeItem('tokenMt4');
    localStorage.removeItem('mt4userinfo');
    localStorage.removeItem('accountinfo');
    localStorage.removeItem('exits');
    localStorage.removeItem('addArr');
    localStorage.removeItem('odd');
    localStorage.removeItem('chartSymbol');
    localStorage.removeItem('getSymbols');
}



function deleteCrmCache() {
    localStorage.removeItem('token');
    localStorage.removeItem('userinfo');
    localStorage.removeItem('email');
}


let subscribers = [];

function onAccessTokenFetched() {
    subscribers.forEach((callback) => {
        callback();
    })
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback)
}


class http {
    static imageurl = baseConfig.imageUrl;
    static imageBase64Url = baseConfig.imageBase64Url;

    static async request(url, method, params) {
        return await instance.request({
            url: url,
            method: method,
            params: params
        })
    }

    static async get(url, params) {
        return await instance.get(url, { params: params })
    }

    static async post(url, params) {
        //var postss = QS.stringify(params);
        //console.log(params);
        return await instance.post(url, params);
    }

    static async put(url, params) {
        return await instance.put(url, QS.stringify(params))
    }

    static async delete(url, params) {
        return await instance.delete(url, { params: QS.stringify(params) })
    }

    static async upload(url, params) {
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
            formData.append(key, params[key])
        });
        let config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        return await instance.post(url, formData, config)
    }
}

export default http
