export default {
    "app": {
        "login": "サインインします",
        "language": "言語",
        "accountpwdlogin": "アカウントパスワードでログインします",
        "pleaseinputaccount": "アカウントを入力してください",
        "pleaseinputpassword": "パスワードを入力してください",
        "forgetpassword": "忘れた?",
        "refundpassword": "パスワードを取得します",
        "noaccount": "アカウントはありません",
        "registernow": "今すぐ登録してください",
        "loginemailorlogin": "電子メール/アカウント",
        "sildesuccesstext": "検証に合格!",
        "sildefailtext": "検証に失敗しました",
        "slidertext": "パズルを完了するには、スライダーをドラッグします",
        "pleaseinputrepassword": "パスワードをもう一度入力してください",
        "clickLoadMore": "[詳細を読み込む] をクリックします",
        "noMoreData": "これ以上",
        more: '詳細',
        account: 'アカウント',
        transactionignal: '交易信号',
        switchAccount: 'アカウントを切り替え',
        myCard: '私のカード',
        improve: '銀行カードの情報を完備してください。',
        recomment: "おすすめ",
        binding: "カードを縛る",
        bind: "他の銀行カードをバインドする",
        common: "共に",
        zhang: "枚",
        add: "追加",
        center: "アプリケーションセンター",
        promote: "促進する",
        security: "安全防護",
        photograph: "写真を撮る",
        photo: "携帯のアルバム",
        not: 'いけません',
        emailErr: "あなたの入力をチェックしてもう一度試してください",
        email: "不正なメールアドレス",
    },
    closePositionsFirst: "最初に清算する!",
    hasSubscription: "シグナルアカウントにはコピートレードユーザーがいます",
    uploadFileRangeSize: 'ファイルサイズは3MBを超えません',
    sumProfit: "総収入",
    netprofitincludefee: "净利润（分析费用）",
    "pleaseinputsinglaname": "信号名を入力してください",
    "pleaseinputpercentagesignalcom": "シグナルコミッションを入力してください",
    "pleaseinputpercentageparentcom": "紹介手数料を入力してください",
    commission: "手数料",
    "Status": "状態",
    "ordernumber": "注文番号",
    "android": "アンドロイド",
    "ios": "リンゴ",
    "windows": "デスクトップ",
    "sendemailsuccess": "送信に成功しました",
    "sendemailfailure": "送信に失敗しました",
    "accountdisabled": "アカウントは無効になっています",
    "emailnotexisting": "メールボックスが存在しません",
    "alreadysubmitunscribe": "避難申請が提出されました",
    "createfailpleasetryagainlater": "操作に失敗しました",
    "alreadnotcopy": "これ以上注文はありません",
    "directly": "直属の部下",
    "secondary": "依存",
    "parent": "紹介者",
    "singalhaveoption": "シグナルソースにはポジションがあります",
    "referralLink": "プロモーションリンク",
    "clickToCopy": "[コピー] をクリックします",
    resetCrmPwd: 'パスワードを再設定する',
    pleaseConfirmNewPwdBefore: '',
    pleaseConfirmNewPwdAfter: 'に新しいパスワードを設定する',
    "retrievepwd": "パスワードを取得します",
    "resetpwdlinksendtoyouremail": "パスワードの変更リンクは、登録メールアドレスに送信されますので、確認してください!",
    "pleaseinputemail": "メールアドレスを入力してください",
    "notrightformatemail": "メールボックスの形式が正しくありません",
    "ok": "確認",
    "wrongnameorpassword": "間違ったアカウントまたはパスワード",
    "clicktologinroregister": "[サインイン/登録] をクリックします",
    "completematerial": "完璧な情報",
    "personalinformation": "個人情報",
    "identityinformaiton": "ドキュメント情報",
    "perfectsuccess": "完璧な成功",
    "phone": "電話だ",
    "areacode": "市外局番",

    "pleaseinputphone": "番号を入力してください",
    "country": "国籍",
    "pleaseselectcountry": "国を選択してください",
    "province": "領地",
    "pleaseselectprovince": "領地を選択してください",
    "city": "市",
    "pleaseselectcity": "市区町村を選択してください",
    "username": "ユーザー名",
    "address": "アドレスです",
    "pleaseinputaddress": "住所を入力してください",
    "submit": "コミットします",
    "cancel": "キャンセルします",
    "front": "正面",
    "back": "反対",
    "proofofaddress": "住所の証明",
    "opentradeaccount": "取引口座を開設します",
    "openagentaccount": "代理店口座を開設します",

    "tohome": "ホーム ページに戻ります",
    "uploadsuccess": "アップロードは成功しました",
    "uploading": "アップロード中",
    "pleaseuploadpicture": "画像をアップロードしてください",
    "pleaseinputprovinceorcity": "州または市区町村を入力してください",
    "openaccount": "口座を開設する",
    "churujin": "出/入金",
    "copyorder": "注文書",
    "wallet": "財布",
    "mycustomers": "私の部下",
    "mydocument": "私のID",
    "iwanttopromote": "私は促進するつもりです",
    "downloadtradeclient": "取引側をダウンロードします",
    "myapplication": "私のアプリケーション",
    "applycopy": "申請書類",
    "copyapply": "伝票要求",
    "mycopy": "私の注文",
    "myapplycopy": "私のアプリケーション",
    "applysignal": "信号を適用します",
    "signalapply": "信号アプリケーション",

    "mysignal": "私の信号",
    "notlogin": "まだサインインしていませんか?",
    "areyousurethatlogout": "[OK] をクリックして終了しますか?",
    "toback": "を返します",
    "avater": "アバター",
    "email": "メールボックス",
    "unbound": "バインドされません",
    "pleaseinputphone": "電話番号を記入してください",
    "success": "操作は成功しました",
    "copyapply": "伝票要求",
    "tips": "ヒント",
    "notrepeatandnotsignal": "このアカウントは、注文を繰り返すのではなく、シグナルソースではありません!",
    "signalname": "信号ソース",
    "pleaseselect": "選択してください",
    "copyaccount": "口座番号を確認します",
    "copytype": "注文方法",
    "copyvalue": "注文値",
    "copydirection": "一方向を追う",
    "pleaseinput": "入力してください",
    "reverse": "逆",
    "positive": "順方向",

    "percentage": "パーセント",
    "fixedlot": "固定手数",
    "equityratio": "正味比率",
    "percentageholder": "入力してください (%)",
    "fixedlotholder": "固定ハンド、最小0.01を入力してください",
    "equityratioholder": "入力を禁止します",
    "copypercentagemustgreaterzero": "注文の割合は 0 より大きくする必要があります",
    "copyvolumesamllest": "固定手数最小0.01",
    "createfailpleasetryagainlater": "操作に失敗しました",
    "systemerror": "システム エラーです",
    "createsuccess": "操作は成功しました",
    "signalname": "信号ソース",
    "signalstate": "信号ソースの状態",
    "copystate": "注文ステータス",
    "clicktoloadmore": "[詳細を読み込む] をクリックします",
    "nomore": "これ以上",
    "SignalCom": "信号手数料",
    "profitShareLogin": "配当金勘定",
    "ParentCom": "紹介手数料",
    "unavailable": "使用できません",
    "normal": "が使用できます",
    "failure": "失敗",
    "applying": "申請中",
    "success": "成功",
    "disable": "無効です",
    "deleted": "は削除されました",
    "areyousureleavecopy": "注文をキャンセルしますか?",
    "lots": "手",
    "none": "なし",
    "pleaseselect": "選択してください",
    "login": "アカウント",
    "pleaseinputpercentage": "入力してください (%)",
    "loginnoeempty": "アカウントを空にすることはできません",
    "signalnamenotempty": "シグナル名は空白にしないでください",
    "signalcommduringzerotoonehundred": "信号ソースの手数料は0〜100%である必要があります",
    "parentcommduringzerotoonehundred": "紹介手数料は0~100%です。",
    "thesumcommmusetlessonehundred": "コミッションの合計は100%を超える必要があります。",
    "revoke": "避難",

    "alllogins": "すべてのアカウント",
    "applyforunsubscribe": "避難を申請する",
    "areyousuredisalbesiganl": "この信号を無効にしますか?",
    "areyousureactivethesiganl": "この信号を有効にしますか?",
    "areyousuredeletethesignal": "このシグナルを削除しますか?",
    "disabled": "無効にします",
    "applying": "処理中",
    "cooperationsignal": "協力の信号",
    "freesignal": "川と湖の信号",
    "AllProfit": "総利益",
    "WeekProfit": "週の利益",
    "MonthProfit": "月次利益",
    "ProfitPercent": "利益率",
    "Name": "信号ソース",
    "CreateTime": "作成時刻",
    "banlancetrend": "ファンドチャート($)",
    "signaldetail": "信号の詳細",
    "Balance": "残高",
    "Equity": "純資産",
    "Deposit": "総預金",
    "Withdrawal": "総出金",

    "AllProfit": "総収益",
    "Profit2": "損失",
    "Profit": "純利益(料金込み)",
    "ProfitPercent": "利益率",
    "Volume": "ボリューム",
    "TicketCount": "注文数量",
    "TicketBuy": "複数注文の数量",
    "TicketSell": "空の注文の数",
    "ProfitCount1": "収益性の高い注文",
    "ProfitCount2": "損失注文",
    "tradesummary": "取引プロファイル",
    "TradeNum": "合計ボリューム",
    "TradeCycle": "取引日数",
    "MaxDrawdown": "最大回撤",
    "WeekProfit": "ほぼ1週間の利益",
    "MonthProfit": "ほぼ1ヶ月の収益",
    "Copy": "今すぐ注文します",
    "tokenexpried": "認定資格の有効期限が切れた場合は、再度ログインしてください",
    "termail": "取引ターミナル",

    "download": "ダウンロードしてください",
    "sort": "並べ替え",
    "sortByTime": "時間",
    "sortByAmount": "金額",
    "sortByProfit": "利益",
    "sortByDeposit": "入金",
    "sortByWithdrawal": "金の外",
    "allAccount": "すべてのアカウント",
    "notLoggenIn": "まだサインインしていません",
    "confirmExit": "システムを終了しますか?",
    "developing": "機能は、まだアップしています",
    "dataerror": "あなたの入力をチェックして、もう一度お試しください！",
    "success": "操作は成功しました!",
    "networkError": "ネットワーク エラーです",
    "codeSendSuccess": "確認コードが正常に送信されました!",
    "codeSendFailed": "確認コードの送信に失敗しました。",
    "tryAgainLater": "後で試してください!",
    "verificationCode": "確認コード",
    "sendVerificationCode": "確認コードを送信します",
    "pleaseInputVerificationCode": "確認コードを入力してください",
    "loading": "処理中",
    "commonTools": "常用ツール",
    "changePassword": "パスワードを変更",
    "customerManagement": '顧客管理',
    "Register": {
        "register": "サインアップします",
        "hasAccount": "既にアカウントがあります",
        "loginNow": "今すぐサインインします",
        "emailFormatError": "メールボックスの形式が正しくありません",
        "pwdStrongError": "パスワードの強度が不十分です",
        "mailAlreadyExists": "アカウントが登録されていますので、直接ログインしてください!",
        "registerFailed": "登録メールの送信に失敗しましたので、カスタマーサービスにお問い合わせください!",
        "activeExpired": "有効期限が切れているのを確認し、メールのアクティブ化を再送信します",
        "activeSuccess": "ライセンス認証は成功しました",
        "userEmail": "メールボックス",
        "userName": "名前",
        "userPassword": "パスワードです",
        "userPasswordAgain": "パスワードを確認します",
        "userAgent": "紹介者",
        "registerSuccess": "登録は成功しました, アクティベーションのためのメールボックスに移動!",
        "pwdNotMatch": "回入力したパスワードが一致しません"
    },
    "NewAccount": {
        "accountApplication": "アカウントをリクエストします",
        "verifyExpired": "検証に失敗しました",
        "alreadyMaxCount": "アカウント数が上限に達しました",
        "loginTypeError": "間違ったアカウント カテゴリです",
        "loginOutOfRange": "アカウントが範囲外です!",
        "loginOpenSuccess": "口座開設に成功!",
        "loginApplicationSuccess": "口座開設申請が成功しました!",
        "loginApplicationFailed": "口座開設申請の提出に失敗しました!",
        "accountType": "アカウントの種類",
        "chooseAccountType": "アカウントの種類を選択します",
        "leverage": "レバレッジ",
        "chooseLeverage": "レバレッジを選択します",
        "submitApplication": "アプリケーションを送信します",
        "tradingAccount": "取引口座",
        "agentAccount": "プロキシ アカウント"
    },
    "Funds": {
        "deposit": "入金",
        "withdrawals": "引き出し",
        "transfer": "転送します",
        "login": "アカウント",
        "chooseLogin": "アカウントを選択します",
        "submitApplication": "アプリケーションを送信します",
        "withdrawalsMoneyUsd": "出金金額($)",
        "pleaseInputWithdrawalsMoneyUsd": "出金金額を入力してください",
        "depositMoney": "入金金額",
        "depositMoneyUsd": "入金金额$",
        "pleaseInputDepositMoney": "入金金額を入力してください",
        "pleaseInputDepositMoneyUsd": "入金金额($)",
        "freeBalance": "利用可能な残高",
        "currency": "通貨の選択",
        "pleaseChooseCurrency": "通貨を選択してください",
        "bankInfo": "銀行情報",
        "pleaseChooseBankInfo": "銀行情報を選択してください",
        "balanceError": "取得に失敗しました。",
        "noBankCard": "回収銀行を追加してください",
        "notEnoughMoney": "利用可能な残高が不足しています",
        "subscribersOnlyWithdrawOnSunday": "毎週日曜日に出金リクエストを送信してください!",
        "dailyWithdrawalsLimit": "出金は1日の上限に達しています!",
        "applicationSuccess": "申請は成功しました!",
        "applicationError": "申請の提出に失敗しました!",
        "transAmount": "転送金額",
        "pleaseInputTransAmount": "振込金額を入力してください",
        "transLogin": "転送アカウント",
        "pleaseInputTransLogin": "振込口座を入力してください",
        "bankCardUsdtBtc": "財布",
        "walletAddress": "回収先住所",
        "bankCard": "銀行カード",
        "edit": "編集",
        "bankCardAdd": "新しい銀行情報",
        "bankCardEdit": "銀行情報を編集します",

        "bankName": "銀行名",
        "pleaseInputBankName": "銀行名を入力してください",
        "bankCardId": "銀行カード番号",
        "pleaseInputBankCardId": "銀行カード番号を入力してください",
        "bankUsdtAdd": "USDT アドレスを追加しました",
        "bankUsdtEdit": "USDT アドレスを編集します",
        "bankCardUsdt": "USDT アドレス",
        "pleaseInputBankCardUsdt": "USDT 回収先住所を入力してください",
        "bankBtcAdd": "新しい BTC アドレスを追加します",
        "bankBtcEdit": "BTC アドレスを編集します",
        "bankCardBtc": "BTC アドレス",

        "pleaseInputBankCardBtc": "BTC 回収先住所を入力してください",
        "swiftCode": "SWIFTコード",
        "pleaseInputSwiftCode": "SWIFT Code (null 可)",
        "branchName": "支店名",
        "pleaseInputBranchName": "支店名を入力してください",
        "bankAddress": "銀行の住所",
        "pleaseInputBankAddress": "銀行の住所を入力してください",
        "payChannel": "支払チャネル",
        "pleaseInputPayChannel": "支払いチャネルを選択してください",
        "doNotRepeatUpload": "アップロードを繰り返し実行しないでください",
        "errorImageFormat": "画像をアップロードしてください",
        "uploadCert": "資格情報をアップロードします",
        "pleaseChoosePaymentChannel": "支払いチャネルを選択してください",
        "processing": "処理中",
        "refused": "が拒否されました",
        "completed": "完了です",
        "viewCert": "伝票を表示します",
        "minWithdrawal": "最低出金額:",
        "maxMinDeposit": 'お支払い金額:',
        "usdtTypeName": 'チェーン名',
        "totalAmount": '全部の金額',
        "withdrawal": '現金で出す',
        "recharge": 'チャージ',
        "bankAccount": '銀行口座',
        "forbiddenToLogin": "ログイン禁止",
    },
    "Leverage": {
        "changeLeverage": "レバレッジを調整します",
        "leverage": "レバレッジ",
        "leverageOld": "元のレバレッジ",
        "leverageNew": "新しいレバレッジ",
        "pleaseChooseLeverageNew": "レバレッジを選択してください"
    },
    "ChangePwd": {
        "changeCrmPwd": "CRM ログイン パスワードを変更します",
        "changeMt4Pwd": "MT5 パスワードを変更します",
        "mainPassword": "取引パスワード",
        "investorPassword": "読み取り専用パスワード",
        "userName": "ユーザー名",
        "password": "パスワードです",
        "pleaseInputPassword": "パスワードを入力してください (8 桁以上)",
        "passwordAgain": "パスワードを確認します",
        "pleaseInputPasswordAgain": "確認パスワードを入力してください(8桁以上)",
        "pwdChangeSuccess": "パスワードの変更に成功しました",
        "passwordType": "パスワードの種類",
        "pleaseChoosePasswordType": "パスワードの種類を選択してください",
        pwdLengthError: "パスワードの長さは8〜18桁です",
        validationFailure: '検証に失敗しました。もう一度やり直してください',
        setPasswordSuccess: 'パスワードが正常にリセットされました'
    },
    "AccountInfo": {
        "userList": "ユーザーの一覧",
        "leverage": "レバレッジ",
        "group": "グループ",
        "equity": "純資産",
        "balance": "残高",
        "credit": "クレジット",
        "freeMargin": "利用可能なマージン",
        "regDate": "口座開設日",
        "moreDetail": "詳細について"
    },
    "Menu": {
        "home": "ホーム",
        "report": "レポート",
        "signal": "信号",
        "mine": "私の",
        "profile": "個人情報",
        "accountInfo": "アカウント情報",
        "bankInfo": "銀行情報",
        "idInfo": "ドキュメント情報",
        "accountManagent": "アカウント管理",
        "updateCrmPwd": "CRM ログイン パスワードを変更します",
        "updateMt4Pwd": "取引パスワードを変更します",
        "changeLeverage": "レバレッジを調整します",
        "fundsManagent": "出入金",
        "deposit": "入金",
        "withdrawals": "引き出し",
        "transfer": "転送します",
        "fundsHistory": "アカウントは、水を流す",
        "reportCenter": "レポート センター",
        "closeReport": "ポジションのクローズレポート",
        "positionReport": "ポジションレポート",
        "depositReport": "パフォーマンス レポート",
        "creditReport": "信用報告書",
        "commissionReport": "コミッションレポート",
        "commissionTotal": "コミッション統計",
        "summaryReport": "レポートを要約します",
        "community": "単一のコミュニティに追いつく",
        "myCopy": "単一のコミュニティに追いつく",
        "mySignal": "単一のコミュニティに追いつく",
        "cancel": "キャンセルします",
        "totalBalance": "残高の合計",
        "totalWithdrawal": "総出金",
        "totalDeposit": "総預金",
        "logOut": "終了する",
        "aboutUs": "私たちについて",
        "myApplyApplication": "私のアプリケーション",
        "openAccount": "口座を開設する",
        "depositOrWithdrawal": "出/入金",
        "copyTrade": "注文書",
        "wallet": "財布",
        "underling": "私の顧客",
        "credentials": "私のID",
        "myLink": "私は促進するつもりです",
        "downloadMetatrade": "取引側をダウンロードします",
        "createSignal": "信号を適用します",
        "mySignal": "私の信号",
        "myApplySignalLog": "私のアプリケーション",
        "aplyForCopy": "申請書類",
        "myCopies": "私の注文",
        "myApplyCopyLog": "私のアプリケーション",
        "toTrading": "取引に行く",
        "performance": "業績報告書",
    },
    "Report": {
        "commissionTotal": "コミッション統計",
        "volume": "ボリューム",
        "balance": "残高",
        "swaps": "在庫手数料",
        "taxes": "税金",
        "credit": "クレジット",
        "depossitTotal": "入金",
        "withdrawalTotal": "金の外",
        "takeProfit": "テイクプロフィット",
        "stopLoss": "ストップロス",
        "charges": "手数料",
        "commission": "コミッション",
        "commissionAgent": "代理店手数料",
        "shareProfit": "配当",
        "signalCommission": "シグナルソース手数料",
        "totalCommission": "手数料の合計",
        "pleaseChooseLogin": "アカウントを選択してください"
    },
    "alreadywassignal": "すでに信号源です",
    "loginnotexist": "アカウントが存在しません",
    "alreadycopyingsignal": "注文中です",
    "alreadyfollowthesignal": "すでに現在の信号に追いついています",
    "thisloginsubmitsignalapply": "シグナル申請が提出されました",
    "alreadysubmitfolowapply": "伝票要求が送信されました",
    Mt4ApiMenu: {
        quote: "引用",
        chart: "チャート",
        trade: "トレード",
        history: "歴史",
        settings: "設定",
        agreement: "ユーザー規約",
        privacyPolicy: "個人情報保護方針",
        aboutUs: "私たちに関しては",
        loginToCrm: "管理",
        loginToMt4: "トレード"
    },
    Mt4ApiTrade: {
        margin: "マージン",
        marginFree: "フリーマージン",
        marginLevel: "前払率",
        profit: "利益",
        selectDate: "日付",
        stopLoss: 'S/L',
        takeProfit: 'T/P',
        pendingPrice: '価格',
        pendingExpDate: '有効期限',
        ticket: 'チケット',
        delete: '削除',
        addSymbol: "シンボルを追加",
        spread: "展開する",
        high: "高い",
        low: "低",
        showAll: "すべてクエリ",
        searchSymbol: "検索記号",
        tradeWarning: "注意！ 取引は市場の状況で実行されます、要求された価格との違いは重要かもしれません！",
        newAccount: "新しいアカウントt",
        openNewAccount: "アカウントを開く",
        openNewAccountDesc: "ブローカーを選択し、アカウントを登録して、取引を学び、戦略をテストします",
        loginWithNewAccount: "既存のアカウントにログインする",
        loginWithNewAccountDesc: "ログイン/パスワードを使用して、選択したブローカーに接続します",
        account: "アカウント",
        password: "パスワード",
        toCrm: "メンバーセンター",
        positionOrder: 'ポジション',
        pendingOrder: '保留中の注文',
        closeOrder: '閉じる',
        deleteOrder: '削除',
        modifyOrder: '変更する',
        trade: 'トランザクション',
        buyLimit: '限定購入',
        buyStop: 'ストップロスを購入する',
        sellLimit: '販売制限',
        sellStop: 'ストップロスを売る',
        marketExecution: '市場執行',
        sellByMarket: '市場価格で販売',
        buyByMarket: '市場での購入'
    },
    KLineChart: {
        time: "T",
        open: "O",
        close: "C",
        high: "H",
        low: "L",
        volume: "V",
        candleSolid: "蜡烛实心",
        candleSStroke: "蜡烛空心",
        candleUpStroke: "蜡烛涨空心",
        candleDownStroke: "蜡烛跌空心",
        candleOhlc: "OHLC",
        candleArea: "面积图",
    },
    LoginIsError: "ログインに失敗しました",
    LogoutIsError: "ログアウトに失敗しました",
    DataIsNULL: "データが空です",
    AcceptRequestError: "要求が失敗しました",
    InvalidSlOrTp: "無効なテイクプロフィットとストップロス",
    CheckStopsAndExpirationError: "無効なテイクプロフィットとストップロス",
    CheckTradeRequestError: "要求が失敗しました",
    GetKdataError: "要求が失敗しました",
    GetSymbolByCurrencyError: "要求が失敗しました",
    GetOpenOrderByTicketError: "要求が失敗しました",
}