import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const state = {
    token: localStorage.getItem("token"),
    tokenMt4: localStorage.getItem("tokenMt4"),
    tabbarShow: true,//CRM Tabbar
    tabbarCurrentShow: "Crm",//Tabbar
    email: localStorage.getItem("email"),
    userinfo: getuserinfo(),
    mt4userinfo: getmt4userinfo(),
    addStock: [],
    klineTime:'M1',//K线分时图
    klineType:'candle_solid',

    tvsymbol: "XAUUSD"
};

function getuserinfo() {
    let userinfo = localStorage.getItem("userinfo");
    if (userinfo != null) {
        return JSON.parse(userinfo);
    }
    userinfo = { avatar: "", name: "xxxxxx", email: "xxxxxxx" };
    return userinfo;
}

function getmt4userinfo() {
    let info = localStorage.getItem("mt4userinfo");
    if (info != null) {
        return JSON.parse(info);
    }
    info = { login: "", server: "", clientid: "" };
    return info;
}


import mutations from "@/store/mutations";
import actions from "@/store/actions";
import getters from "@/store/getters";


export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})
