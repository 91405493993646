function isPhone(phone) {
    let reg = /^[1][34578]\d{9}$/;
    return reg.test(phone);
}

function isCode(code) {
    return code.length === 4;
}

function isEmail(str) {
    var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(str).toLowerCase());
}



function fixedNumberWithoutRouding() {
    Number.prototype.toFixed = function (n) {
        if (n > 20 || n < 0) {
            //debugger 
            //throw new RangeError('toFixed() digits argument must be between 0 and 20');
        }
        const number = this;
        if (isNaN(number) || number >= Math.pow(10, 21)) {
            return number.toString();
        }
        if (typeof (n) == 'undefined' || n == 0) {
            return (Math.round(number)).toString();
        }

        let result = number.toString();
        const arr = result.split('.');

        // 整数的情况
        if (arr.length < 2) {
            result += '.';
            for (let i = 0; i < n; i += 1) {
                result += '0';
            }
            return result;
        }

        const integer = arr[0];
        const decimal = arr[1];
        if (decimal.length == n) {
            return result;
        }
        if (decimal.length < n) {
            for (let i = 0; i < n - decimal.length; i += 1) {
                result += '0';
            }
            return result;
        }
        result = integer + '.' + decimal.substr(0, n);
        const last = decimal.substr(n, 1);

        return result;
    };

    Date.prototype.Format = function (fmt) {
        var o = {
            'M+': this.getMonth() + 1, // 月份
            'd+': this.getDate(), // 日
            'h+': this.getHours(), // 小时
            'm+': this.getMinutes(), // 分
            's+': this.getSeconds(), // 秒
            'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
            S: this.getMilliseconds() // 毫秒
        };
        // 年
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (this.getFullYear() + '').substr(4 - RegExp.$1.length)
            );
        }
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length) // 如果两位补0
                );
            }
        }
        return fmt;
    };
}
/**
 * 
 * @param {any} value
 */
function formatType(value) {
    switch (value) {
        case 0: return "buy";
        case 1: return "sell";
        case 2: return "balance";
        case 3: return "credit";
        case 4: return "charge";
        case 5: return "correction";
        case 6: return "Bouns";
        case 7: return "commission";
        case 8: return "commission daily";
        case 9: return "commission monthly";
        case 10: return "agent daily";
        case 11: return "agent monthly";
        case 12: return "interestrate";
        case 13: return "buy canceled";
        case 14: return "sell canceled";
        case 15: return "devidend";
        case 16: return "devidend franked";
        case 17: return "tax";
        case 18: return "agent";
        case 19: return "last";//SO_COMPENSATION
    }
}
/**
 * 格式化价格
 * @param {any} value
 * @param {any} len
 */
function forPrice(value, len) {
    var f = parseFloat(value);
    if (isNaN(f)) {
        return false;
    }
    var f = Math.round(value * 100) / 100;
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
        rs = s.length;
        s += '.';
    }
    while (s.length <= rs + len) {
        s += '0';
    }
    return parseFloat(value).toFixed(len);
}



/**
 * 浮点数加法运算
 * @param {any} arg1
 * @param {any} arg2
 */
function floatAdd(arg1, arg2) {
    var r1, r2, m;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
}

/**
 * 浮点数减法运算
 * @param {any} arg1
 * @param {any} arg2
 */
function floatSub(arg1, arg2) {
    var r1, r2, m, n;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    //动态控制精度长度
    n = (r1 = r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

/**
 * 浮点数乘法运算
 * @param {any} arg1
 * @param {any} arg2
 */
function floatMul(arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try { m += s1.split(".")[1].length } catch (e) { }
    try { m += s2.split(".")[1].length } catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
/**
 * 浮点数除法运算
 * @param {any} arg1
 * @param {any} arg2
 * @param {any} digit
 */
function floatDev(arg1, arg2, digit) {
    var t1 = 0, t2 = 0, r1, r2;
    try { t1 = arg1.toString().split(".")[1].length } catch (e) { }
    try { t2 = arg2.toString().split(".")[1].length } catch (e) { }
    r1 = Number(arg1.toString().replace(".", ""));
    r2 = Number(arg2.toString().replace(".", ""));;
    var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString();
    var result2 = result.split(".")[1];
    result2 = result2.substring(0, digit > result2.length ? result2.length : digit);

    return Number(result.split(".")[0] + "." + result2);
}

export { isPhone, isCode, isEmail, fixedNumberWithoutRouding, formatType, forPrice, floatAdd, floatSub, floatMul, floatDev }
