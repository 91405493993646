export default {
    "app": {
        "login": "ลงชื่อเข้าใช้",
        "language": "ภาษา",
        "accountpwdlogin": "เข้าสู่ระบบด้วยรหัสผ่านบัญชี",
        "pleaseinputaccount": "กรุณาใส่หมายเลขบัญชี",
        "pleaseinputpassword": "กรุณาใส่รหัสผ่าน",
        "forgetpassword": "ลืมไปแล้วเหรอ?",
        "refundpassword": "รับรหัสผ่านกลับคืนมา",
        "noaccount": "ไม่มีบัญชี",
        "registernow": "ลงทะเบียนตอนนี้",
        "loginemailorlogin": "อีเมล / บัญชี",
        "sildesuccesstext": "ตรวจสอบผ่าน!",
        "sildefailtext": "การตรวจสอบความถูกต้องล้มเหลว โปรดลองอีกครั้ง",
        "slidertext": "ลากแถบเลื่อนเพื่อทําปริศนาให้เสร็จสมบูรณ์",
        "pleaseinputrepassword": "กรุณาใส่รหัสผ่านของคุณอีกครั้ง",
        "clickLoadMore": "คลิกโหลดเพิ่มเติม",
        "noMoreData": "ไม่มีอะไรมากไปเลย",
        more: 'ยิ่งขึ้น',
        account: 'อธิบายแจกแจง',
        transactionignal: 'สัญญาณการซื้อขาย',
        switchAccount: 'เปลี่ยนบัญชี',
        myCard: 'บัตรของฉัน',
        improve: 'กรุณากรอกข้อมูลบัตรธนาคาร',
        recomment: "แนะนำ",
        binding: "ผูกบัตร",
        bind: "ผูกบัตรธนาคารอื่นๆ",
        common: "คอมมอน",
        zhang: "แผ่ออก",
        add: "เพิ่มบัต",
        center: "ศูนย์ประยุกต์",
        promote: "ส่งเสริม",
        security: "การป้องกันความปลอดภัย",
        photograph: "ถ่ายรูป",
        photo: "อัลบั้มโทรศัพท์มือถือ",
        not: 'ไม่เติม',
        emailErr: "โปรดตรวจสอบการป้อนข้อมูลของคุณและลองใหม่อีกครั้ง",
        email: "รูปแบบอีเมลที่ไม่ถูกต้อง",
    },
    closePositionsFirst: "กรุณาปิดตำแหน่งก่อน!",
    hasSubscription: "บัญชีสัญญาณมีผู้ใช้คัดลอก!",
    uploadFileRangeSize: 'ขนาดไฟล์ไม่เกิน 3MB',
    sumProfit: "รายได้รวม",
    netprofitincludefee: "กำไรสุทธิ (รวมค่าใช้จ่าย)",
    "pleaseinputsinglaname": "กรุณาใส่ชื่อสัญญาณ",
    "pleaseinputpercentagesignalcom": "กรุณาใส่ค่าคอมมิชชันสัญญาณ",
    "pleaseinputpercentageparentcom": "โปรดป้อนค่าคอมมิชชั่นผู้แนะนํา",
    commission: "ค่าคอมมิชชั่น",
    "Status": "สถานะ",
    "ordernumber": "หมายเลขใบสั่ง",
    "android": "แอนดรอยด์",
    "ios": "แอปเปิ้ล",
    "windows": "เดสก์ท็อป",
    "sendemailsuccess": "ส่งเสร็จเรียบร้อยแล้ว",
    "sendemailfailure": "การส่งล้มเหลว โปรดลองอีกครั้งในภายหลัง",
    "accountdisabled": "บัญชีถูกปิดใช้งาน",
    "emailnotexisting": "ไม่มีกล่องจดหมายอยู่",
    "alreadysubmitunscribe": "คําขออพยพถูกส่งแล้ว",
    "createfailpleasetryagainlater": "การดําเนินการล้มเหลว โปรดลองอีกครั้งในภายหลัง",
    "alreadnotcopy": "ไม่มีการต่อคําสั่งอีกต่อไป",
    "directly": "โดยตรงภายใต้",
    "secondary": "ผู้ใต้บังคับบัญชา",
    "parent": "ผู้แนะนํา",
    "singalhaveoption": "แหล่งสัญญาณมีตําแหน่ง",
    "referralLink": "ลิงก์โปรโมท",
    "clickToCopy": "คลิกคัดลอก",
    resetCrmPwd: 'รีเซ็ตรหัสผ่าน',
    pleaseConfirmNewPwdBefore: 'ตั้งรหัสผ่านใหม่สำหรับ',
    pleaseConfirmNewPwdAfter: '',
    "retrievepwd": "รับรหัสผ่านกลับคืนมา",
    "resetpwdlinksendtoyouremail": "ลิงก์เปลี่ยนรหัสผ่านจะถูกส่งไปยังอีเมลการลงทะเบียนของคุณโปรดทราบ!",
    "pleaseinputemail": "กรุณาใส่กล่องจดหมายของคุณ",
    "notrightformatemail": "กล่องจดหมายมีรูปแบบไม่ถูกต้อง",
    "ok": "ยืนยัน",
    "wrongnameorpassword": "หมายเลขบัญชีหรือรหัสผ่านไม่ถูกต้อง",
    "clicktologinroregister": "คลิกลงชื่อเข้าใช้/ลงชื่อสมัครใช้",
    "completematerial": "ปรับปรุงข้อมูล",
    "personalinformation": "ข้อมูลส่วนบุคคล",
    "identityinformaiton": "ข้อมูลเอกสาร",
    "perfectsuccess": "ความสําเร็จที่สมบูรณ์แบบ",
    "phone": "โทรศัพท์",
    "areacode": "รหัสพื้นที่",
    "pleaseinputphone": "กรุณาใส่หมายเลข",
    "country": "สัญชาติ",
    "pleaseselectcountry": "โปรดเลือกประเทศ",
    "province": "จังหวัด",
    "pleaseselectprovince": "โปรดเลือกจังหวัด",
    "city": "เมือง",
    "pleaseselectcity": "โปรดเลือกเมือง",
    "username": "ชื่อผู้ใช้",
    "address": "ที่อยู่",
    "pleaseinputaddress": "กรุณาใส่ที่อยู่",
    "submit": "82.1",
    "cancel": "ยกเลิก",
    "front": "ด้านหน้า",
    "back": "ด้านตรงข้าม",
    "proofofaddress": "หลักฐานที่อยู่",
    "opentradeaccount": "เปิดบัญชีซื้อขาย",
    "openagentaccount": "เปิดบัญชีตัวแทน",
    "tohome": "กลับไปที่หน้าแรก",
    "uploadsuccess": "การอัปโหลดสําเร็จแล้ว",
    "uploading": "อัปโหลด",
    "pleaseuploadpicture": "กรุณาอัปโหลดภาพ",
    "pleaseinputprovinceorcity": "กรุณากรอกจังหวัดหรือเมือง",
    "openaccount": "เปิดบัญชี",
    "churujin": "ออก/ออก",
    "copyorder": "ตามใบปลิว",
    "wallet": "กระเป๋าตังค์",
    "mycustomers": "ผู้หมวดของฉัน",
    "mydocument": "บัตรประชาชนของฉัน",
    "iwanttopromote": "ฉันจะโปรโมตมัน",
    "downloadtradeclient": "ดาวน์โหลดด้านการค้า",
    "myapplication": "ใบสมัครของฉัน",
    "applycopy": "ขอใบปลิว",
    "copyapply": "ใบสมัครสําหรับตั๋ว",
    "mycopy": "ใบปลิวของฉัน",
    "myapplycopy": "ใบสมัครของฉัน",
    "applysignal": "ขอสัญญาณ",
    "signalapply": "การขอสัญญาณ",
    "mysignal": "สัญญาณของฉัน",
    "notlogin": "คุณยังไม่ได้ลงชื่อเข้าใช้เลยใช่ไหม",
    "areyousurethatlogout": "ยืนยันการจบการทํางานหรือไม่",
    "toback": "กลับ",
    "avater": "อวตาร",
    "email": "กล่องจดหมาย",
    "unbound": "ไม่ถูกผูกไว้",
    "pleaseinputphone": "กรุณากรอกหมายเลขโทรศัพท์",
    "success": "การดําเนินการเสร็จเรียบร้อยแล้ว",
    "copyapply": "ใบสมัครสําหรับตั๋ว",
    "tips": "เคล็ดลับ",
    "notrepeatandnotsignal": "บัญชีนี้ไม่สามารถทําซ้ําการต่อคําสั่งและไม่ใช่แหล่งที่มาของสัญญาณ!",
    "signalname": "แหล่งสัญญาณ",
    "pleaseselect": "โปรดเลือก",
    "copyaccount": "บัญชีที่ต่อบัญชี",
    "copytype": "วิธีเดียวในการต่อคําสั่ง",
    "copyvalue": "ค่าที่บันทึก",
    "copydirection": "ติดตามทิศทาง",
    "pleaseinput": "กรุณาใส่",
    "reverse": "ย้อนกลับ",
    "positive": "บวก",
    "percentage": "เปอร์เซ็นต์",
    "fixedlot": "จํานวนล็อตคงที่",
    "equityratio": "อัตราส่วนของมูลค่าสุทธิ",
    "percentageholder": "กรุณาใส่ (%)",
    "fixedlotholder": "กรุณาใส่ล็อตคงที่ขั้นต่ํา 0.01",
    "equityratioholder": "ห้ามป้อน",
    "copypercentagemustgreaterzero": "เปอร์เซ็นต์การใบสั่งต้องมากกว่า 0",
    "copyvolumesamllest": "ล็อตคงที่ขั้นต่ํา 0.01",
    "createfailpleasetryagainlater": "โปรดลองอีกครั้งในภายหลังเมื่อการดําเนินการล้มเหลว",
    "systemerror": "ข้อผิดพลาดของระบบ",
    "createsuccess": "การดําเนินการเสร็จเรียบร้อยแล้ว",
    "signalname": "แหล่งสัญญาณ",
    "signalstate": "สถานะแหล่งสัญญาณ",
    "copystate": "สถานะการใบสั่ง",
    "clicktoloadmore": "คลิกโหลดเพิ่มเติม",
    "nomore": "ไม่มีอะไรมากไปเลย",
    "SignalCom": "ค่าคอมมิชชันสัญญาณ",
    "profitShareLogin": "บัญชีเงินปันผล",
    "ParentCom": "ค่าคอมมิชชันผู้แนะนํา",
    "unavailable": "ไม่พร้อมใช้งาน",
    "normal": "ที่มีอยู่ของ",
    "failure": "ล้มเหลว",
    "applying": "ในใบสมัคร",
    "success": "ความสําเร็จ",
    "disable": "ปิดใช้งาน",
    "deleted": "ลบแล้ว",
    "areyousureleavecopy": "ยืนยันการยกเลิกการใบสั่งหรือไม่?",
    "lots": "มือ",
    "none": "ไม่มี",
    "pleaseselect": "โปรดเลือก",
    "login": "บัญชี",
    "pleaseinputpercentage": "กรุณาใส่ (%)",
    "loginnoeempty": "บัญชีต้องไม่ว่างเปล่า",
    "signalnamenotempty": "ชื่อสัญญาณต้องไม่ว่างเปล่า",
    "signalcommduringzerotoonehundred": "ค่าคอมมิชชั่นแหล่งสัญญาณควรอยู่ระหว่าง 0 ถึง 100%",
    "parentcommduringzerotoonehundred": "ค่าคอมมิชชั่นผู้แนะนําควรอยู่ระหว่าง 0 ถึง 100%",
    "thesumcommmusetlessonehundred": "ค่าคอมมิชชั่นรวมต้องไม่เกิน 100%",
    "revoke": "อพยพ",
    "alllogins": "บัญชีทั้งหมด",
    "applyforunsubscribe": "ขออพยพ",
    "areyousuredisalbesiganl": "ตกลงที่จะปิดการใช้งานสัญญาณนี้หรือไม่",
    "areyousureactivethesiganl": "คุณแน่ใจหรือไม่ว่าเปิดใช้งานสัญญาณนี้",
    "areyousuredeletethesignal": "คุณแน่ใจหรือไม่ว่าคุณต้องการเอาสัญญาณนี้ออก",
    "disabled": "ปิดใช้งาน",
    "applying": "ระหว่างดําเนินการ",
    "cooperationsignal": "สัญญาณความร่วมมือของ",
    "freesignal": "สัญญาณยุทธภพ",
    "AllProfit": "กําไร",
    "WeekProfit": "กําไรรายสัปดาห์",
    "MonthProfit": "กําไรรายเดือน",
    "ProfitPercent": "ปัจจัยกําไร",
    "Name": "แหล่งสัญญาณ",
    "CreateTime": "เวลาที่สร้าง",
    "banlancetrend": "แผนภูมิการเคลื่อนไหวของเงินทุน ($)",
    "signaldetail": "รายละเอียดสัญญาณ",
    "Balance": "ดุล",
    "Equity": "มูลค่าสุทธิ",
    "Deposit": "การฝากเงินทั้งหมด",
    "Withdrawal": "การออกเงินทั้งหมด",
    "Profit2": "ขาดทุน",
    "Profit": "กําไรสุทธิ(รวมถึงค่าธรรมเนียม)",
    "ProfitPercent": "ปัจจัยกําไร",
    "Volume": "ปริมาณการซื้อขาย",
    "TicketCount": "ปริมาณในใบสั่ง",
    "TicketBuy": "ปริมาณเดียว",
    "TicketSell": "จํานวนคําสั่งที่ว่างเปล่า",
    "ProfitCount1": "คําสั่งซื้อที่ทํากําไรได้",
    "ProfitCount2": "คําสั่งขาดทุน",
    "tradesummary": "ภาพรวมธุรกรรม",
    "TradeNum": "ปริมาณการซื้อขายรวม",
    "TradeCycle": "จํานวนวันของธุรกรรม",
    "MaxDrawdown": "การเบิกถอนสูงสุด",
    "WeekProfit": "รายได้เกือบหนึ่งสัปดาห์",
    "MonthProfit": "รายได้เกือบเดือน",
    "AllProfit": "รวมก ากับ",
    "Copy": "ทําการใบสั่งเดี๋ยวนี้",
    "tokenexpried": "เมื่อการรับรองหมดอายุ โปรดลงชื่อเข้าใช้อีกครั้ง",
    "termail": "เทอร์มินัลการซื้อขาย",
    "download": "ดาวน์โหลด",
    "sort": "เรียงลําดับ",
    "sortByTime": "เวลา",
    "sortByAmount": "ยอดเงิน",
    "sortByProfit": "กําไร",
    "sortByDeposit": "การฝากเงิน",
    "sortByWithdrawal": "การออกเงิน",
    "allAccount": "บัญชีทั้งหมด",
    "notLoggenIn": "คุณยังไม่ได้ลงชื่อเข้าใช้",
    "confirmExit": "คุณแน่ใจหรือไม่ว่าคุณต้องการออกจากระบบ",
    "developing": "ฟังก์ชันไม่ได้ออนไลน์ ~",
    "dataerror": "กรุณาตรวจสอบการป้อนข้อมูลของคุณและลองใหม่อีกครั้ง",
    "success": "การดําเนินงานที่ประสบความสําเร็จ!",
    "networkError": "ข้อผิดพลาดของเครือข่าย",
    "codeSendSuccess": "รหัสยืนยันถูกส่งเรียบร้อยแล้ว!",
    "codeSendFailed": "การส่งรหัสยืนยันล้มเหลว!",
    "tryAgainLater": "กรุณาลองในภายหลัง!",
    "verificationCode": "รหัสยืนยัน",
    "sendVerificationCode": "ส่งรหัสยืนยัน",
    "pleaseInputVerificationCode": "กรุณาใส่รหัสยืนยัน",
    "loading": "ระหว่างดําเนินการ",
    "commonTools": "เครื่องมือทั่วไป",
    "changePassword": "เปลี่ยนรหัสผ่าน",
    "customerManagement": 'การจัดการลูกค้า',
    "forbiddenToLogin": "ห้ามเข้าสู่ระบบ",
    "Register": {
        "register": "ลงชื่อสมัครใช้",
        "hasAccount": "มีบัญชีอยู่แล้ว",
        "loginNow": "ลงชื่อเข้าใช้ตอนนี้",
        "emailFormatError": "กล่องจดหมายมีรูปแบบไม่ถูกต้อง",
        "pwdStrongError": "รหัสผ่านไม่แข็งแรงพอ",
        "mailAlreadyExists": "บัญชีนี้ลงทะเบียนแล้วโปรดเข้าสู่ระบบโดยตรง!",
        "registerFailed": "การลงทะเบียนสําหรับการส่งอีเมลล้มเหลวโปรดติดต่อฝ่ายบริการลูกค้า!",
        "activeExpired": "ตรวจสอบว่าหมดอายุแล้ว โปรดส่งข้อความอีกครั้งเพื่อเปิดใช้งาน",
        "activeSuccess": "การเปิดใช้งานสําเร็จ",
        "userEmail": "กล่องจดหมาย",
        "userName": "ชื่อ",
        "userPassword": "รหัสผ่าน",
        "userPasswordAgain": "ยืนยันรหัสผ่าน",
        "userAgent": "ผู้แนะนํา",
        "registerSuccess": "ลงทะเบียนเรียบร้อยแล้วโปรดไปที่กล่องจดหมายเพื่อเปิดใช้งาน!",
        "pwdNotMatch": "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน!"
    },
    "NewAccount": {
        "accountApplication": "สมัครบัญชี",
        "verifyExpired": "การตรวจสอบความถูกต้องล้มเหลว",
        "alreadyMaxCount": "จํานวนบัญชีถึงขีดจํากัดแล้ว",
        "loginTypeError": "ประเภทบัญชีที่ไม่ถูกต้อง",
        "loginOutOfRange": "บัญชีอยู่นอกช่วง!",
        "loginOpenSuccess": "การเปิดบัญชีสําเร็จแล้ว!",
        "loginApplicationSuccess": "ส่งใบสมัครเปิดบัญชีเรียบร้อยแล้ว!",
        "loginApplicationFailed": "การส่งใบสมัครเปิดบัญชีล้มเหลว!",
        "accountType": "ชนิดบัญชี",
        "chooseAccountType": "เลือกประเภทบัญชี",
        "leverage": "เลเวอเรจ",
        "chooseLeverage": "เลือกเลเวอเรจ",
        "submitApplication": "ส่งใบสมัคร",
        "tradingAccount": "บัญชีซื้อขาย",
        "agentAccount": "บัญชีตัวแทน"
    },
    "Funds": {
        "deposit": "การฝากเงิน",
        "withdrawals": "การถอนเงิน",
        "transfer": "โอนเงิน",
        "login": "บัญชี",
        "chooseLogin": "เลือกบัญชี",
        "submitApplication": "ส่งใบสมัคร",
        "withdrawalsMoneyUsd": "จํานวนเงินที่ถอน($)",
        "pleaseInputWithdrawalsMoneyUsd": "โปรดป้อนจํานวนเงินถอนเงิน",
        "depositMoney": "ยอดเงินการฝากเงิน",
        "depositMoneyUsd": "ยอดเงินการฝากเงิน $",
        "pleaseInputDepositMoney": "กรุณาใส่ยอดเงินที่ฝากไว้",
        "pleaseInputDepositMoneyUsd": "ยอดเงินการฝากเงิน $",
        "freeBalance": "ยอดดุลที่พร้อมใช้งาน",
        "currency": "การเลือกสกุลเงิน",
        "pleaseChooseCurrency": "โปรดเลือกสกุลเงิน",
        "bankInfo": "ข้อมูลธนาคาร",
        "pleaseChooseBankInfo": "โปรดเลือกข้อมูลธนาคาร",
        "balanceError": "รับความล้มเหลวโปรดลองในภายหลัง!",
        "noBankCard": "กรุณาเพิ่มธนาคารผู้รับเงิน",
        "notEnoughMoney": "ยอดดุลที่พร้อมใช้งานไม่เพียงพอ",
        "subscribersOnlyWithdrawOnSunday": "ผู้ใช้ที่ติดตามโปรดส่งคําขอออกทุกวันอาทิตย์!",
        "dailyWithdrawalsLimit": "การถอนเงินถึงขีด จํากัด สูงสุดวันเดียว!",
        "applicationSuccess": "ส่งใบสมัครประสบความสําเร็จ!",
        "applicationError": "การส่งใบสมัครล้มเหลว!",
        "transAmount": "ยอดเงินที่โอนย้าย",
        "pleaseInputTransAmount": "โปรดป้อนจํานวนเงินโอน",
        "transLogin": "โอนเงินเข้าบัญชี",
        "pleaseInputTransLogin": "กรุณาใส่บัญชีโอนเงิน",
        "bankCardUsdtBtc": "กระเป๋าตังค์",
        "walletAddress": "ที่อยู่สําหรับการเรียกเก็บเงิน",
        "bankCard": "บัตรธนาคาร",
        "edit": "แก้ไข",
        "bankCardAdd": "เพิ่มข้อมูลธนาคารใหม่",
        "bankCardEdit": "แก้ไขข้อมูลธนาคาร",
        "bankName": "ชื่อธนาคาร",
        "pleaseInputBankName": "กรุณาใส่ชื่อธนาคาร",
        "bankCardId": "หมายเลขบัตรธนาคาร",
        "pleaseInputBankCardId": "กรุณาใส่หมายเลขบัตรธนาคาร",
        "bankUsdtAdd": "เพิ่มที่อยู่ USDT ใหม่",
        "bankUsdtEdit": "แก้ไขที่อยู่ USDT",
        "bankCardUsdt": "ที่อยู่ USDT",
        "pleaseInputBankCardUsdt": "โปรดป้อนที่อยู่สําหรับการเรียกเก็บเงิน USDT",
        "bankBtcAdd": "เพิ่มที่อยู่ BTC ใหม่",
        "bankBtcEdit": "แก้ไขที่อยู่ BTC",
        "bankCardBtc": "ที่อยู่ BTC",
        "pleaseInputBankCardBtc": "โปรดป้อนที่อยู่สําหรับการเรียกเก็บเงินของ BTC",
        "swiftCode": "รหัส SWIFT",
        "pleaseInputSwiftCode": "รหัส SWIFT (ว่าง)",
        "branchName": "ชื่อสาขา",
        "pleaseInputBranchName": "กรุณาใส่ชื่อสาขา",
        "bankAddress": "ที่อยู่ธนาคาร",
        "pleaseInputBankAddress": "กรุณาใส่ที่อยู่ธนาคาร",
        "payChannel": "ช่องทางการชําระเงิน",
        "pleaseInputPayChannel": "โปรดเลือกช่องทางการชําระเงิน",
        "doNotRepeatUpload": "อย่าอัปโหลดซ้ํา",
        "errorImageFormat": "กรุณาอัปโหลดภาพ",
        "uploadCert": "อัปโหลดเวาเชอร์",
        "pleaseChoosePaymentChannel": "โปรดเลือกช่องทางการชําระเงิน",
        "processing": "ระหว่างดําเนินการ",
        "refused": "ถูกปฏิเสธ",
        "completed": "เสร็จสมบูรณ์",
        "viewCert": "ดูใบสําคัญ",
        "minWithdrawal": "จำนวนถอนขั้นต่ำ:",
        "maxMinDeposit": 'จำนวนเงินที่ชำระ:',
        usdtTypeName: 'ชื่อลูกโซ่',
        totalAmount: 'ผลรวม',
        withdrawal: 'ถอนเงิน',
        recharge: 'เติม',
        bankAccount: 'บัญชีธนาคาร',
    },
    "Leverage": {
        "changeLeverage": "ปรับเลเวอเรจ",
        "leverage": "เลเวอเรจ",
        "leverageOld": "เลเวอเรจเดิม",
        "leverageNew": "เลเวอเรจใหม่",
        "pleaseChooseLeverageNew": "โปรดเลือกเลเวอเรจ"
    },
    "ChangePwd": {
        "changeCrmPwd": "แก้ไขรหัสผ่านการเข้าสู่ระบบ CRM",
        "changeMt4Pwd": "แก้ไขรหัสผ่าน MT5",
        "mainPassword": "รหัสผ่านการซื้อขาย",
        "investorPassword": "รหัสผ่านแบบอ่านอย่างเดียว",
        "userName": "ชื่อผู้ใช้",
        "password": "รหัสผ่าน",
        "pleaseInputPassword": "โปรดป้อนรหัสผ่าน (อย่างน้อย 8 หลัก)",
        "passwordAgain": "ยืนยันรหัสผ่าน",
        "pleaseInputPasswordAgain": "โปรดป้อนรหัสผ่านยืนยัน (อย่างน้อย 8 หลัก)",
        "pwdChangeSuccess": "การเปลี่ยนแปลงรหัสผ่านสําเร็จ โปรดลงชื่อเข้าใช้อีกครั้ง",
        "passwordType": "ชนิดของรหัสผ่าน",
        "pleaseChoosePasswordType": "โปรดเลือกประเภทรหัสผ่าน",
        pwdLengthError: "ความยาวรหัสผ่าน 8 ถึง 18 หลัก",
        validationFailure: 'การยืนยันล้มเหลว โปรดลองอีกครั้ง',
        setPasswordSuccess: 'รีเซ็ตรหัสผ่านสำเร็จ'
    },
    "AccountInfo": {
        "userList": "รายชื่อผู้ใช้",
        "leverage": "เลเวอเรจ",
        "group": "กลุ่ม",
        "equity": "มูลค่าสุทธิ",
        "balance": "ดุล",
        "credit": "เครดิต",
        "freeMargin": "มาร์จิ้นที่มีอยู่",
        "regDate": "วันที่เปิดบัญชี",
        "moreDetail": 'รายละเอียดเพิ่มเติม',
    },
    "Menu": {
        "home": "หน้าแรก",
        "report": "รายงาน",
        "signal": "สัญญาณ",
        "mine": "ของฉัน",
        "profile": "ข้อมูลส่วนบุคคล",
        "accountInfo": "ข้อมูลบัญชี",
        "bankInfo": "ข้อมูลธนาคาร",
        "idInfo": "ข้อมูลเอกสาร",
        "accountManagent": "การจัดการบัญชี",
        "updateCrmPwd": "แก้ไขรหัสผ่านการเข้าสู่ระบบ CRM",
        "updateMt4Pwd": "แก้ไขรหัสผ่านดีล",
        "changeLeverage": "ปรับเลเวอเรจ",
        "fundsManagent": "การฝากและถอนเงิน",
        "deposit": "การฝากเงิน",
        "withdrawals": "การถอนเงิน",
        "transfer": "โอนเงิน",
        "fundsHistory": "การไหลของบัญชี",
        "reportCenter": "ศูนย์รายงาน",
        "closeReport": "รายงานการปิดตําแหน่ง",
        "positionReport": "รายงานตําแหน่ง",
        "depositReport": "รายงานผลการดําเนินงาน",
        "creditReport": "รายงานเครดิต",
        "commissionReport": "รายงานค่าคอมมิชชัน",
        "commissionTotal": "สถิติค่าคอมมิชชัน",
        "summaryReport": "สรุปรายงาน",
        "community": "ชุมชนที่ติดตาม",
        "myCopy": "ชุมชนที่ติดตาม",
        "mySignal": "ชุมชนที่ติดตาม",
        "cancel": "ยกเลิก",
        "totalBalance": "ยอดดุลรวม",
        "totalWithdrawal": "การออกเงินทั้งหมด",
        "totalDeposit": "การฝากเงินทั้งหมด",
        "logOut": "ล้มเลิก",
        "aboutUs": "เกี่ยวกับเรา",
        "myApplyApplication": "ใบสมัครของฉัน",
        "openAccount": "เปิดบัญชี",
        "depositOrWithdrawal": "ออก/ออก",
        "copyTrade": "ตามใบปลิว",
        "wallet": "กระเป๋าตังค์",
        "underling": "ลูกค้าของฉัน",
        "credentials": "บัตรประชาชนของฉัน",
        "myLink": "ฉันจะโปรโมตมัน",
        "downloadMetatrade": "ดาวน์โหลดด้านการค้า",
        "createSignal": "ขอสัญญาณ",
        "mySignal": "สัญญาณของฉัน",
        "myApplySignalLog": "ใบสมัครของฉัน",
        "aplyForCopy": "ขอใบปลิว",
        "myCopies": "ใบปลิวของฉัน",
        "myApplyCopyLog": "ใบสมัครของฉัน",
        "toTrading": "ไปซื้อขาย",
        "performance": "รายงานผลการปฏิบัติงาน",
    },
    "Report": {
        "commissionTotal": "สถิติค่าคอมมิชชัน",
        "volume": "ปริมาณการซื้อขาย",
        "balance": "ดุล",
        "swaps": "ค่าธรรมเนียมสินค้าคงคลัง",
        "taxes": "ภาษี",
        "credit": "เครดิต",
        "depossitTotal": "การฝากเงิน",
        "withdrawalTotal": "การออกเงิน",
        "takeProfit": "ทํากําไรได้",
        "stopLoss": "หยุดการสูญเสีย",
        "charges": "ค่าธรรมเนียมการจัดการ",
        "commission": "คอมมิชชั่น",
        "commissionAgent": "ค่าคอมมิชชันของเอเจนซี่",
        "shareProfit": "เงินปันผล",
        "signalCommission": "ค่าคอมมิชชันของแหล่งสัญญาณ",
        "totalCommission": "คอมมิชชั่นรวม",
        "pleaseChooseLogin": "โปรดเลือกบัญชี"
    },
    "alreadywassignal": "มันเป็นแหล่งสัญญาณอยู่แล้ว",
    "loginnotexist": "ไม่มีบัญชีอยู่",
    "alreadycopyingsignal": "กําลังทํารายการ",
    "alreadyfollowthesignal": "มีสัญญาณปัจจุบันอยู่แล้ว",
    "thisloginsubmitsignalapply": "ใบสมัครสัญญาณถูกส่งแล้ว",
    "alreadysubmitfolowapply": "ส่งใบสมัครสําหรับการส่งเอกสารแล้ว",
    Mt4ApiMenu: {
        quote: "คำคม",
        chart: "แผนภูมิ",
        trade: "ธุรกรรม",
        history: "ประวัติศาสตร์",
        settings: "การตั้งค่า",
        agreement: "ข้อตกลงการใช้",
        privacyPolicy: "นโยบายความเป็นส่วนตัว",
        aboutUs: "เกี่ยวกับเรา",
        loginToCrm: "ด้านการจัดการ",
        loginToMt4: "ด้านธุรกรรม"
    },
    Mt4ApiTrade: {
        margin: "การชำระล่วงหน้า",
        marginFree: "ล่วงหน้าที่มีจำหน่าย",
        marginLevel: "ระดับมาร์จิ้น",
        profit: "กำไร",
        selectDate: "วันที่",
        stopLoss: 'S/L',
        takeProfit: 'T/P',
        pendingPrice: 'ราคา',
        pendingExpDate: 'หมดอายุ',
        ticket: 'ใบสั่ง',
        delete: 'ลบ',
        addSymbol: "เพิ่มตัวเลือก",
        spread: "การแพร่กระจาย",
        high: "สูงสุด",
        low: "ต่ำสุด",
        showAll: "ดูทั้งหมด",
        searchSymbol: "ค้นหาพันธุ์การค้า",
        tradeWarning: "โปรดทราบ! การซื้อขายจะดำเนินการตามสภาวะตลาดความแตกต่างกับราคาที่ร้องขออาจมีนัยสำคัญ!",
        newAccount: "บัญชีใหม่",
        openNewAccount: "ลงทะเบียนบัญชีใหม่",
        openNewAccountDesc: "เลือกโบรกเกอร์และลงทะเบียนบัญชีเพื่อเรียนรู้การซื้อขายและทดสอบกลยุทธ์ของคุณ",
        loginWithNewAccount: "เข้าสู่ระบบบัญชีที่มีอยู่",
        loginWithNewAccountDesc: "ใช้ล็อกอิน / รหัสผ่านของคุณเพื่อเชื่อมต่อกับโบรกเกอร์ที่คุณเลือก",
        account: "บัญชีธุรกรรม",
        password: "รหัสผ่าน",
        toCrm: "ศูนย์สมาชิก",
        positionOrder: 'ตำแหน่ง',
        pendingOrder: 'คำสั่งซื้อที่รอดำเนินการ',
        closeOrder: 'การชำระบัญชี',
        deleteOrder: 'ลบ',
        modifyOrder: 'แก้ไข',
        trade: 'ธุรกรรม',
        buyLimit: 'ซื้อจำกัด',
        buyStop: 'ซื้อหยุด',
        sellLimit: 'ขายจำกัด',
        sellStop: 'หยุดขาย',
        marketExecution: 'การดำเนินการตามตลาด',
        sellByMarket: 'ขายตามตลาด',
        buyByMarket: 'ซื้อตามท้องตลาด'

    },
    KLineChart: {
        time: "T",
        open: "O",
        close: "C",
        high: "H",
        low: "L",
        volume: "V",
        candleSolid: "蜡烛实心",
        candleSStroke: "蜡烛空心",
        candleUpStroke: "蜡烛涨空心",
        candleDownStroke: "蜡烛跌空心",
        candleOhlc: "OHLC",
        candleArea: "面积图",
    },
    LoginIsError: "เข้าสู่ระบบล้มเหลว",
    LogoutIsError: "การออกจากระบบล้มเหลว",
    DataIsNULL: "ข้อมูลว่างเปล่า",
    AcceptRequestError: "คำขอล้มเหลว",
    InvalidSlOrTp: "Take Profit และ Stop Loss ไม่ถูกต้อง",
    CheckStopsAndExpirationError: "Take Profit และ Stop Loss ไม่ถูกต้อง",
    CheckTradeRequestError: "คำขอล้มเหลว",
    GetKdataError: "คำขอล้มเหลว",
    GetSymbolByCurrencyError: "คำขอล้มเหลว",
    GetOpenOrderByTicketError: "คำขอล้มเหลว",
}