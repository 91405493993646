import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {fixedNumberWithoutRouding} from './utils/commons'
import '@/assets/css/reset.css'
import '@/assets/css/border.css'
import fastCLick from 'fastclick'
fastCLick.attach(document.body);
import http from '@/utils/http'
import Vant  from "vant";

import 'vant/lib/index.css'
import 'vue-easytable/libs/themes-base/index.css'
import 'font-awesome/css/font-awesome.min.css'
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';

import { VTable, VPagination } from 'vue-easytable'
Vue.component(VTable.name, VTable);
Vue.component(VPagination.name, VPagination);
Vue.use(Vant);



import {i18n, vantLocales} from './lang'
//console.log('当前的语言是：', i18n.locale)
vantLocales(i18n.locale)

import Api from './api/index';
Vue.prototype.$api = Api;

import './assets/icon/iconfont.css'


Vue.prototype.$http = http;
fixedNumberWithoutRouding();

// 注册时可以配置额外的选项

import { Lazyload } from 'vant';

Vue.use(Lazyload);
Vue.use(Lazyload, {
  lazyComponent: true,
});

new Vue({ 
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');

