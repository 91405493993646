export default {
    app: {
        login: 'Login',
        language: "Language",
        accountpwdlogin: "Account Password Login",
        pleaseinputaccount: "Please input account",
        pleaseinputpassword: "Please input password",
        forgetpassword: "Forgot?",
        refundpassword: "Recover Password",
        noaccount: "No account",
        registernow: "Register",
        loginemailorlogin: "Email/Account",
        sildesuccesstext: "Verification passed!",
        sildefailtext: "Verification failed, please try again",
        slidertext: "Drag the slider to complete the puzzle",
        pleaseinputrepassword: "Please input password again",
        clickLoadMore: 'Click to load more',
        noMoreData: 'No more',
        delete:"Delete",
        more: 'More',
        account: 'Account',
        transactionignal: 'Transaction signal',
        switchAccount: 'Switch account',
        leverage: 'Leverage',
        promote: "promote",
        myCard: 'My card',
        improve: 'Please improve information',
        recomment: "Recommend",
        binding: "Add",
        bind: "Bind bank cards",
        common: "Common",
        zhang: "Zhang",
        add: "Add",
        center: "Application Center",
        promote: "Promote",
        security: "Safety protection",
        photograph: "Photograph",
        photo: "Mobile photo album",
        not: 'Not required',
        emailErr: "Please check your input and try again! ",
        email: "Incorrect email address format",
    },
    closePositionsFirst:"Close positions first!",
    hasSubscription: "Signal account has copytrade users!",
    uploadFileRangeSize: 'The file size cannot exceed 3MB',
    sumProfit: "Total Profit",
    netprofitincludefee: "Net profit [including fees]",
    "pleaseinputsinglaname": "Input signal name",
    "pleaseinputpercentagesignalcom": "Signal commission level(%)",
    "pleaseinputpercentageparentcom": "Referrer commission level(%)",
    referralLink: 'Referral Link',
    clickToCopy: 'Click to copy',
    commission: "佣金",
    "Status": "State",
    "ordernumber": "Order Number",
    "sendemailsuccess": "The send was successful",
    "sendemailfailure": "Sending failed, please try again later",
    "accountdisabled": "The account is disabled",
    "emailnotexisting": "The mailbox does not exist",
    "alreadysubmitunscribe": "An evacuation request has been submitted",
    "createfailpleasetryagainlater": "The operation failed, please try again later",
    "alreadnotcopy": "No longer follow-up",
    "directly": "Immediate",
    "secondary": "Subordinate",
    "parent": "Recommender",
    "singalhaveoption": "The signal source has positions",
    "android": "Android",
    "ios": "apple",
    "windows": "Desktop",

    //start
    resetCrmPwd: 'Reset Password',
    pleaseConfirmNewPwdBefore: 'Set new password for',
    pleaseConfirmNewPwdAfter: '',
    retrievepwd: "Retrieve Password",
    resetpwdlinksendtoyouremail: "The reset password link will be sent to your registered email address, please check !",
    pleaseinputemail: "Please input email",
    notrightformatemail: "Incorrect email format ",
    ok: "Confirm",
    wrongnameorpassword: "Incorrect account or password",
    clicktologinroregister: "Click to log in/register",
    completematerial: "Information",
    personalinformation: "Personal",
    identityinformaiton: "Certificate",
    perfectsuccess: "Success",
    phone: "Phone",
    areacode: "Area Code",
    pleaseinputphone: "Please input phone number",
    country: "Country",
    pleaseselectcountry: "Please select country",
    province: "Province",
    pleaseselectprovince: "Please select province",
    city: "City",
    pleaseselectcity: "Please select city",
    username: "Name",
    address: "Address",
    pleaseinputaddress: "Please input address",
    submit: "Submit",
    cancel: "Cancel",
    front: "Front",
    back: "Back",
    proofofaddress: "Proof of Address",
    opentradeaccount: "Open trading account",
    openagentaccount: "Open agent account",
    tohome: "Home",
    uploadsuccess: "Upload successfully",
    uploading: "Uploading",
    pleaseuploadpicture: "Please upload a picture",
    pleaseinputprovinceorcity: "Please input the province or city",
    openaccount: "New account",
    churujin: "Withdrawal/Deposit",
    copyorder: "Copytrade",
    wallet: "Wallet",
    mycustomers: "My subordinates",
    mydocument: "My Document",
    iwanttopromote: "I want to promote",
    downloadtradeclient: "Download Trading Terminal",
    myapplication: "My Application",
    applycopy: "Application copy",
    copyapply: "Copytrade Application",
    mycopy: "My Copy",
    myapplycopy: "My Application",
    applysignal: "Become a signal",
    signalapply: "Signal Application",
    mysignal: "My Signal",
    notlogin: "You have not logged in yet?",
    areyousurethatlogout: "Are you sure to log out?",
    toback: "Back",
    avater: "Avater",
    email: "Email",
    unbound: "Unbound",
    pleaseinputphone: "Please fill in cell phone number",
    success: "Success",
    copyapply: "Copytrade Application",
    tips: "hint",
    notrepeatandnotsignal: "This account cannot be repeated and is not a signal source!",
    signalname: "Signal Source",
    pleaseselect: "Please select",
    copyaccount: "Account",
    copytype: "Type",
    copyvalue: "Value",
    copydirection: "Direction",
    pleaseinput: "Please input",
    reverse: "Reverse",
    positive: "Positive",
    percentage: "Percentage",
    fixedlot: "Fixed Lot",
    equityratio: "Equity Ratio",
    percentageholder: "Please enter (%)",
    fixedlotholder: "Please enter a fixed lot, the minimum is 0.01",
    equityratioholder: "No input",
    copypercentagemustgreaterzero: "The copytrade percentage must be greater than 0",
    copyvolumesamllest: "The minimum fixed lot size is 0.01",
    createfailpleasetryagainlater: "Failed, please try again later",
    systemerror: "System Error",
    createsuccess: "Succeeded",
    signalname: "Signal",
    signalstate: "State",
    copystate: "Copytrade State",
    clicktoloadmore: "Click to load more",
    nomore: "No more",
    SignalCom: "Signal Com",
    ParentCom: "Referrer",
    profitShareLogin: "Profit sharing account",
    unavailable: "Unavailable",
    normal: "Available",
    failure: "Failed",
    applying: "Applying",
    success: "Success",
    disable: "Disabled",
    deleted: "Deleted",
    areyousureleavecopy: "Are you sure to cancel the copytrade?",
    lots: "Lots",
    none: "None",
    pleaseselect: "Please select",
    login: "Account",
    pleaseinputpercentage: "Please input (%)",
    loginnoeempty: "Account ID must not be empty",
    signalnamenotempty: "The signal name must not be empty",
    signalcommduringzerotoonehundred: "Signal commission should be between 0 and 100%",
    parentcommduringzerotoonehundred: "Referrer commission should be between 0 and 100%",
    thesumcommmusetlessonehundred: "The sum of commissions must not be greater than 100%",
    revoke: "Unsubscribe",
    alllogins: "All accounts",
    applyforunsubscribe: "Apply for early evacuation",
    areyousuredisalbesiganl: "Are you sure to disable this signal?",
    areyousureactivethesiganl: "Are you sure to enable this signal?",
    areyousuredeletethesignal: "Are you sure to delete this signal?",
    disabled: "Disabled",
    applying: "Processing",
    cooperationsignal: "Cooperation Signal",
    freesignal: "Free Signal",
    AllProfit: "Gross Profit",
    WeekProfit: "Weekly Profit",
    MonthProfit: "Monthly Profit",
    ProfitPercent: "Profit Coefficient",
    Name: "Signal Source",
    CreateTime: "Create Time",
    banlancetrend: "Fund Trend Chart ($)",
    signaldetail: "Signal Details",
    Balance: "Balance",
    Equity: "Equity",
    Deposit: "Total Deposit",
    Withdrawal: "Total Withdrawal",
    AllProfit: "Profit",
    Profit2: "Gross Loss",
    Profit: "Net Profit(Including fee)",
    ProfitPercent: "Profit Coefficient",
    Volume: "Trading Volume",
    TicketCount: "Order Quantity",
    TicketBuy: "Long Order Quantity",
    TicketSell: "Short Order Quantity",
    ProfitCount1: "Profit Order",
    ProfitCount2: "Loss Order",
    tradesummary: "Overview",
    TradeNum: "Total Volume",
    TradeCycle: "Trading Days",
    MaxDrawdown: "Max Drawdown",
    WeekProfit: "Profit of last week",
    MonthProfit: "Profit of last month",
    AllProfit: "Gross Profit",
    Copy: "Copy Order Now",
    tokenexpried: "Authentication expired, please log in again",
    termail: "Trading Terminal",
    download: "Download",
    sort: 'Sort',
    sortByTime: 'Time',
    sortByAmount: 'Amount',
    sortByProfit: 'Profit',
    sortByDeposit: 'Deposit',
    sortByWithdrawal: 'Withdrawal',
    allAccount: 'All accounts',
    notLoggenIn: 'You have not logged in yet',
    confirmExit: 'Are you sure to log out?',
    developing: 'The function is not online yet',
    dataerror: "Please check your input and try again!",
    success: "Success",
    networkError: "Network Error",
    codeSendSuccess: "Verification code sent successfully!",
    codeSendFailed: "Failed to send verification code!",
    tryAgainLater: "Please try again later!",
    verificationCode: "Code",
    sendVerificationCode: "Send Code",
    pleaseInputVerificationCode: "Please input verification code",
    loading: "Processing",
    commonTools: "Common tools",
    changePassword: "Change Password",
    customerManagement: 'Customer',
    forbiddenToLogin: "ForbiddenToLogin",
    Register: {
        register: "Register",
        hasAccount: "Account existing",
        loginNow: "Log in now",
        emailFormatError: "Email address format is incorrect",
        pwdStrongError: "The password is not strong enough",
        mailAlreadyExists: "The account is already registered, please log in directly!",
        registerFailed: "Failed to send the registration email, please contact customer service!",
        activeExpired: "Verification has expired, please resend the email to activate",
        activeSuccess: "Activation Successful",
        userEmail: "Email",
        userName: "Name",
        userPassword: "Password",
        userPasswordAgain: "Confirm Password",
        userAgent: "Referrer",
        registerSuccess: "Registration is successful, please go to your email to activate!",
        pwdNotMatch: "The Passwords do not match!",
    },
    NewAccount: {
        accountApplication: "Apply for an account",
        verifyExpired: "Verification failed",
        alreadyMaxCount: "The number of accounts has reached the upper limit",
        loginTypeError: "Wrong account type",
        loginOutOfRange: "The account number is out of range!",
        loginOpenSuccess: "Account opened successfully!",
        loginApplicationSuccess: "The account openning application is submitted successfully!",
        loginApplicationFailed: "Submission of account openning application failed!",
        accountType: "Account Type",
        chooseAccountType: "Choose Account Type",
        leverage: "Leverage",
        chooseLeverage: "Choose Leverage",
        submitApplication: "Submit",
        tradingAccount: 'Trading Account',
        agentAccount: 'Agent Account',
    },
    Funds: {
        deposit: "Deposit",
        withdrawals: "Withdrawal",
        transfer: "Transfer",
        login: "Account",
        chooseLogin: "Choose Account",
        submitApplication: "Submit",
        withdrawalsMoneyUsd: "Withdrawal amount($)",
        pleaseInputWithdrawalsMoneyUsd: "Please enter withdrawal amount",
        depositMoney: "Local Amount",
        depositMoneyUsd: "Dollar",
        pleaseInputDepositMoney: "Please enter deposit amount",
        pleaseInputDepositMoneyUsd: "Amount ($)",
        freeBalance: "Balance",
        currency: "Currency",
        pleaseChooseCurrency: "Please choose currency",
        bankInfo: "Bank Information",
        pleaseChooseBankInfo: "Please choose bank information",
        balanceError: "Get balance failed, please try again later!",
        noBankCard: "Please add the receiving bank card",
        notEnoughMoney: "Not enough available balance",
        subscribersOnlyWithdrawOnSunday: "Subscribers， please submit withdrawal application on Sundays!",
        dailyWithdrawalsLimit: "Withdrawal amount reaches the maximum daily limit!",
        applicationSuccess: "The application was submitted successfully!",
        applicationError: "Application submission failed!",
        transAmount: "Amount",
        pleaseInputTransAmount: "Please enter the transfer amount",
        transLogin: "Account",
        pleaseInputTransLogin: "Please enter the transfer account",
        bankCardUsdtBtc: "Wallet",
        walletAddress: 'Wallet Address',
        bankCard: 'Bank Card',
        edit: 'Edit',
        bankCardAdd: "Add Bank Information",
        bankCardEdit: "Edit Bank Information",
        bankName: "Bank Name",
        pleaseInputBankName: "Please input bank name",
        bankCardId: "Bank Card Number",
        pleaseInputBankCardId: "Please enter the bank card number",
        bankUsdtAdd: "Add USDT address",
        bankUsdtEdit: "Edit USDT Address",
        bankCardUsdt: "USDT Address",
        pleaseInputBankCardUsdt: "Please enter the USDT wallet address",
        bankBtcAdd: "Add BTC address",
        bankBtcEdit: "Edit BTC Address",
        bankCardBtc: "BTC Address",
        pleaseInputBankCardBtc: "Please enter the BTC wallet address",
        swiftCode: "SWIFT Code",
        pleaseInputSwiftCode: "Please input SWIFT Code",
        branchName: "Branch Name",
        pleaseInputBranchName: "Please enter the branch name",
        bankAddress: "Bank Address",
        pleaseInputBankAddress: "Please input bank address",
        payChannel: "Payment Channel",
        pleaseInputPayChannel: "Please select a payment channel",
        doNotRepeatUpload: "Do not upload repeatedly",
        errorImageFormat: "Please upload an image",
        uploadCert: "Upload Credentials",
        pleaseChoosePaymentChannel: 'Please choose a payment channel',
        processing: 'Processing',
        refused: 'Rejected',
        completed: 'Completed',
        viewCert: 'View credentials',
        minWithdrawal: 'Minimum amount:',
        maxMinDeposit: 'Amount:',
        usdtTypeName: 'Chain name',
        totalAmount: 'Total amount',
        withdrawal: 'Withdrawal',
        recharge: 'Deposit',
        bankAccount: 'Bank Account',
    },
    Leverage: {
        changeLeverage: 'Change leverage',
        leverage: 'Leverage',
        leverageOld: 'Original leverage',
        leverageNew: 'New leverage',
        pleaseChooseLeverageNew: 'Please choose leverage',
    },
    ChangePwd: {
        changeCrmPwd: 'Reset password',
        changeMt4Pwd: 'Reset trade password',
        mainPassword: 'Main password',
        investorPassword: 'Read-only password',
        userName: 'Name',
        password: 'Password',
        pleaseInputPassword: 'Please enter a password (8 digits at least )',
        passwordAgain: 'Confirm password',
        pleaseInputPasswordAgain: 'Confirm the password (8 digits at least )',
        pwdChangeSuccess: 'Password changed successfully, please log in again',
        passwordType: 'Password Type',
        pleaseChoosePasswordType: 'Please choose a password type',
        pwdLengthError: "Password length is 8 to 18 digits",
        validationFailure: 'Verification failed, please try again',
        setPasswordSuccess: 'Password reset successfully'
    },
    AccountInfo: {
        userList: 'User List',
        leverage: "Leverage",
        group: "Group",
        equity: 'Equity',
        balance: 'Balance',
        credit: 'Credit',
        freeMargin: 'Free Margin',
        regDate: 'Registration date',
        moreDetail: 'More details',
    },
    Menu: {
        home: 'Home',
        report: 'Report',
        signal: 'Signal',
        mine: 'My',
        profile: 'Personal Information',
        accountInfo: 'Account Information',
        bankInfo: 'Bank Information',
        idInfo: 'Certificate',
        accountManagent: 'Account Management',
        updateCrmPwd: 'Reset password',
        updateMt4Pwd: 'Reset trade password',
        changeLeverage: 'Change leverage',
        fundsManagent: 'Fund',
        deposit: "Deposit",
        withdrawals: "Withdrawal",
        transfer: "Transfer",
        fundsHistory: 'Statement',
        reportCenter: 'Report Center',
        closeReport: 'History Trade',
        positionReport: 'Positions',
        depositReport: 'Statement',
        creditReport: 'Credit',
        commissionReport: 'Commission',
        commissionTotal: 'Com. Statistics',
        summaryReport: 'Summary',
        community: 'Copytrade Community',
        myCopy: 'My Copy',
        mySignal: 'Copytrade Community',
        cancel: 'Cancel',
        totalBalance: 'Balance',
        totalWithdrawal: 'Withdrawal',
        totalDeposit: 'Deposit',
        logOut: 'Log out',
        aboutUs: 'About Us',
        myApplyApplication: 'My Application',
        openAccount: 'New account',
        depositOrWithdrawal: 'Funds',
        copyTrade: 'Copytrade',
        wallet: 'Wallet',
        underling: 'My clients',
        credentials: 'My credentials',
        myLink: 'I want to promote',
        downloadMetatrade: 'Download trading terminal',
        createSignal: 'Apply for signal',
        mySignal: 'My Signal',
        myApplySignalLog: 'My Application',
        aplyForCopy: 'Apply for Copytrade',
        myCopies: 'My Copytrade',
        myApplyCopyLog: 'My Application',
        toTrading: "Go trading",
        performance: "Performance",
    },
    Report: {
        commissionTotal: 'Commission Statistics',
        volume: 'Volume',
        balance: 'Balance',
        swaps: 'Swap',
        taxes: 'Taxes',
        credit: 'Credit',
        depossitTotal: 'Deposit',
        withdrawalTotal: 'Withdrawal',
        takeProfit: 'Take Profit',
        stopLoss: 'Stop Loss',
        charges: 'Charge',
        commission: 'Commission Charge',
        commissionAgent: 'Agent fee',
        shareProfit: 'dividend',
        signalCommission: 'Signal commission',
        totalCommission: 'Total Commission',
        pleaseChooseLogin: 'Please choose an account'
    },
    "alreadywassignal": "is already a source of signal",
    "loginnotexist": "The account does not exist",
    "alreadycopyingsignal": "Keeping orders",
    "alreadyfollowthesignal": "already with the current signal",
    "thisloginsubmitsignalapply": "A signal request has been submitted",
    "alreadysubmitfolowapply": "A follow-up request has been submitted",
    Mt4ApiMenu: {
        quote: "Quotes",
        chart: "Chart",
        trade: "Trade",
        history: "History",
        settings: "Settings",
        agreement: "User Agreement",
        privacyPolicy: "Privacy Policy",
        aboutUs: "About Us",
        loginToCrm: "Mobile Crm",
        loginToMt4: "Mobile Trade"
    },
    Mt4ApiTrade: {
        margin: "Margin",
        marginFree: "Free Margin",
        marginLevel: "Margin Level",
        profit: "Profit",
        selectDate: "Date",
        stopLoss: 'S/L',
        takeProfit: 'T/P',
        pendingPrice: 'Price',
        pendingExpDate: 'Expiration',
        ticket: 'Ticket',
        delete: 'Delete',
        addSymbol: "Add Symbol",
        spread: "Spread",
        high: "High",
        low: "Low",
        showAll: "show all",
        searchSymbol: "search symbol",
        tradeWarning: "Attention! The trade will be executed at market conditions,difference with requested price may be significant!",
        newAccount: "New Account",
        openNewAccount: "Open an account",
        openNewAccountDesc: "Choose a broker and register an account to learn trading and test your strategies",
        loginWithNewAccount: "Login to an existing account",
        loginWithNewAccountDesc: "Use your login/password to connect to the broker of you choice",
        account: "Mt Account",
        password: "Password",
        toCrm: "Membership",
        positionOrder: 'Position',
        pendingOrder: 'Pending',
        closeOrder: 'Close',
        deleteOrder: 'Delete',
        modifyOrder: 'Modify',
        trade: 'Trade',
        buyLimit: 'Buy Limit',
        buyStop: 'Buy Stop',
        sellLimit: 'Sell Limit',
        sellStop: 'Sell Stop',
        marketExecution: 'Market Execution',
        sellByMarket: 'sell by market',
        buyByMarket: 'buy by market'

    },
    KLineChart: {
        time: "T",
        open: "O",
        close: "C",
        high: "H",
        low: "L",
        volume: "V",
        candleSolid: "蜡烛实心",
        candleSStroke: "蜡烛空心",
        candleUpStroke: "蜡烛涨空心",
        candleDownStroke: "蜡烛跌空心",
        candleOhlc: "OHLC",
        candleArea: "面积图",
    },
    LoginIsError: "Login failed",
    LogoutIsError: "Logout failed",
    DataIsNULL: "Data is empty",
    AcceptRequestError: "Request failed",
    InvalidSlOrTp: "Invalid Take Profit and Stop Loss",
    CheckStopsAndExpirationError: "Invalid Take Profit and Stop Loss",
    CheckTradeRequestError: "Request failed",
    GetKdataError: "Request failed",
    GetSymbolByCurrencyError: "Request failed",
    GetOpenOrderByTicketError: "Request failed",
}