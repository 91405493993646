<template>
    <van-tabbar v-model="active"
                route
                style="z-index: 9"
                active-color="#13b5b1"
                @change="onChange">
        <van-tabbar-item replace to="/quotes">
            <span>{{ $t("Mt4ApiMenu.quote") }}</span>
            <img slot="icon"
                 slot-scope="props"
                 :src="props.active ? icon.quotes_active : icon.quotes_inactive"
                 alt="" />
        </van-tabbar-item>

        <van-tabbar-item replace to="/chart">
            <span>{{ $t("Mt4ApiMenu.chart") }}</span>
            <img slot="icon"
                 slot-scope="props"
                 :src="props.active ? icon.chart_active : icon.chart_inactive"
                 alt="" />
        </van-tabbar-item>

        <van-tabbar-item replace to="/trade">
            <span>{{ $t("Mt4ApiMenu.trade") }}</span>
            <img slot="icon"
                 slot-scope="props"
                 :src="props.active ? icon.trade_active : icon.trade_inactive"
                 alt="" />
        </van-tabbar-item>

        <van-tabbar-item replace to="/history">
            <span>{{ $t("Mt4ApiMenu.history") }}</span>
            <img slot="icon"
                 slot-scope="props"
                 :src="props.active ? icon.history_active : icon.history_inactive"
                 alt="" />
        </van-tabbar-item>

        <van-tabbar-item replace to="/settings">
            <span>{{ $t("Mt4ApiMenu.settings") }}</span>
            <img slot="icon"
                 slot-scope="props"
                 :src="props.active ? icon.settings_active : icon.settings_inactive"
                 alt="" />
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
    export default {
        name: "TabbarMt4",
        data() {
            return {
                active: 0,
                icon: {
                    quotes_active: require("../assets/image/mt4tabbar/quotes_active.png"),
                    quotes_inactive: require("../assets/image/mt4tabbar/quotes.png"),
                    chart_active: require("../assets/image/mt4tabbar/chart_active.png"),
                    chart_inactive: require("../assets/image/mt4tabbar/chart.png"),
                    trade_active: require("../assets/image/mt4tabbar/trade_active.png"),
                    trade_inactive: require("../assets/image/mt4tabbar/trade.png"),
                    history_active: require("../assets/image/mt4tabbar/history_active.png"),
                    history_inactive: require("../assets/image/mt4tabbar/history.png"),
                    settings_active: require("../assets/image/mt4tabbar/settings_active.png"),
                    settings_inactive: require("../assets/image/mt4tabbar/settings.png"),
                },

                userinfo: this.$store.state.mt4userinfo,
            };
        },
        methods: {
            onChange(active) {
                console.log(active);
                this.$store.dispatch('changeBars', active);
            },
        },
    };
</script>

<style scoped>
    img {
        display: block;
        height: 23px !important;
    }

    .tabbar-item {
        flex: 1;
    }

    .van-tabbar-item--active {
        color: #13b5b1 !important;
        background-color: #fff;
    }
</style>
